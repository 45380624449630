import {useState , useEffect} from 'react'
import { getUserInfo , getPdf , currentUserPDFs , resetPassword } from '../config'
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col
  } from "react-bootstrap";
const Profile = () => {

const [userData, setUserData] = useState("")
const [allPdf, setAllPdf] = useState()  
const [editMode, setEditMode] = useState(false)  
const [userAllPdf, setUserAllPdf]   = useState()  

const receiveUserData = async () => {
    const result = await getUserInfo()
    // console.log("User Data ", result)
    setUserData(result)
}

const allRegisterPdf = async () => {
    var result = await getPdf()
    setAllPdf(result)
    // console.log( 'allPdf', allPdf)
}
// const allSeletPdf = async () => {
//     var result = await currentUserPDFs()
//     setUserAllPdf(result)
//     console.log( 'userallPdf ----------- ', userAllPdf)
// }

useEffect(() => {
    const res = receiveUserData()
    allRegisterPdf()
    // var resu = allSeletPdf()
    // console.log('resu-------',resu)
 },[])

 

const [ form, setForm ] = useState()
const updateForm = (e, key) => {
  setForm( {...form, [key] : e.target.value})
}

// useEffect(() => {
//   console.log('form-------',form,'-----------form')
// //   console.log('userData-------',userData,'-----------userData')
// },[form])

async function sendResetPass(){
  if(form && form.prePassword != '' && form.password != ''){
    // console.log('true-------',form,'-----------form')
    var result = await resetPassword(form)
  }
}



    const {email , firstName , lastName , organization , date, PDF  , selected , profilePic } = userData
    // console.log('=-=-=-=-=-=-=-=-=-=-=-=-', PDF ,'-=-=-=-=-=-=-=-=-=-=-=-=-=-=')
  return (
    <>
      <Container className='py-md-0 py-5' >
        <Row className='justify-content-center '>
          <Col md="5"   >
            <Card className="card-user bshd profilepagebox">
              <Card.Body className='px-3 d-flex flex-column justify-content-center align-items-start position-relative' >
                <div className='profile-edit-btn m-2  rounded-circle' onClick={() => setEditMode(!editMode)} >
                  {
                    editMode ? 
                      <i className="bi bi-x-lg"/>
                    : 
                      <i className="bi bi-pencil-square"/>
                  }
                  
                </div>
                <div className="author d-flex w-100 justify-content-center align-items-center ">
                  <div>
                    {
                    profilePic &&
                    <img
                      alt="..."
                      className="avatar border-gray p-1"
                      src={profilePic}
                    />
                    }
                    {editMode ? '' :
                    <h5 className="title">{firstName}</h5>
                    }
                  </div>
                  
                </div>
                {
                  editMode ? 
                <div className="mb-3 w-100  h-100 d-flex flex-column justify-content-evenly  ">
                  <h1 className='fs-5 pb-0 mb-0 text-center' >Reset Password :</h1>
                  
                  <div className='d-flex flex-column my-1' >
                    <p htmlFor="exampleInputPassword2" aria-autocomplete='true'  className="form-label">Current Password :</p>
                    <input type="password" placeholder='Enter Current password' autoComplete='current-password' className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'prePassword')} />
                  </div>  
                  <div className='d-flex flex-column my-1' >
                    <p htmlFor="exampleInputPassword3" aria-autocomplete='true'  className="form-label">New Password</p>
                    <input type="password" placeholder='Enter New Password' autoComplete='current-password' className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'password')} />
                  </div>
                  <div className=' d-flex justify-content-end' >
                    <div className="border-0  px-3 py-2 text-center submit-btn" onClick={() => sendResetPass()} >
                      Update <i className='bi bi-send' />
                    </div>
                  </div>
                </div>
                :
                <div className="description text-start opacity-75 h-100">
                    <p className='fs-5 my-2'> <b className='me-1'>First Name :</b> {firstName} </p>
                    <p className='fs-5 my-2'> <b className='me-1'>Last Name :</b> {lastName} </p>
                    <p className='fs-5 my-2'> <b className='me-1'>Organization :</b> {organization} </p>
                    <p className='fs-5 my-2'> <b className='me-1'>Email :</b> {email} </p>
                    <p className='fs-5 my-2'> <b className='me-1'>Register Date :</b> {date} </p>
                </div>
                }
                
                
                
              </Card.Body>
            </Card>
          </Col>
          {selected && PDF  ?
            <Col md="7" className=' my-md-0 my-5' >
              <Card className="card-user profilepagebox h-100 bshd text-center pdfsec">
              <Card.Header>
                  <Card.Title as="h3" className='fw-normal' >Download Your PDF</Card.Title>
              </Card.Header>
                <Card.Body className='table-full-width table-responsive' >
                  <Table className="table-hover  ">
                    <thead  >
                      <tr>
                        <th className="border-0">No</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Description</th>
                        <th className="border-0">Download</th>
                      </tr>
                    </thead>
                    <tbody  >
                      {PDF.map((pdf , index) => { 
                          return(
                              <tr key={pdf.downloadUrl} className='pdfitem'  >
                                  <td>{index + 1}</td>
                                  <td>{pdf.title}</td>
                                  <td>{pdf.des}</td>
                                  <td className='d-flex align-items-center px-0 ' ><a href={pdf.downloadUrl} target='_blank' className='w-100 py-1'  > download <i className='nc-icon nc-cloud-download-93 ms-1' /> </a></td>
                              </tr>
                          )
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          :
          ''
          }
        </Row>
      </Container>
    </>
  )
}

export default Profile

