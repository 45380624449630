import React , { useState , useEffect } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import { getUsers , addSelectUser  , getDownloadURL, uploadBytes , storage , ref , db , addSelectUserPDFs , getRealtimeMachineData , addSelectUserMachines } from '../config'
import { setDoc, doc, addDoc, collection , getDoc , updateDoc } from 'firebase/firestore';
import swal from 'sweetalert'
import { getPdf } from '../config';
import axios, { all } from 'axios';



const AdminPanel = () => {
  const [pdfObject, setPdfObject] = useState({})
  const navigate = useNavigate()
  const [ allUsers, setAllUsers] = useState()  
  const [ select, setSelect] = useState()  
  const [ forDependencyArray, setForDependencyArray] = useState()  
  const [ file, setFile] = useState()
  const [ url, setUrl] = useState()
  const [ loading, setLoading] = useState(false)
  const [ form, setForm ] = useState()
  const [ forms, setForms ] = useState()
  const [ allPdf, setAllPdf ] = useState()
  const [ pdfPopup, setPdfPopup ] = useState(false)
  const [ uploadMachinePopup, setUploadMachinePopup ] = useState(false)
  const [ uploadPdfPopup, setUploadPdfPopup ] = useState(false)
  const [ machinePopup, setMachinePopup ] = useState(false)
  const [ selectedUserPdfs , setSelectedUserPdfs ] = useState([])
  const [ selectedUserMachines , setselectedUserMachines ] = useState([])
  const [allMachines, setAllMachines] = useState()
  const [deleteCondition, setDeleteCondition] = useState(false)

  const updateForm = (e, key) => {
    // console.log('form ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----')
    setForm( {...form, [key] : e.target.value})
  }
  
  const updateMachinesForm = (e, key) => {
    // console.log('forms ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----',e.target.value)
    // console.log(key)
    setForms( {...forms, [key] : e.target.value})
  }
  

  // =============================== select =====================================\\

  const uploadPdf = async () => {
    if(form.title && form.des && file){
    try {
    setLoading(true)
    const imageRef = ref(storage, 'uploadedPdf/'+ file.name);
    const uploadPDF = await uploadBytes(imageRef, file);
    const downloadUrl = await getDownloadURL(uploadPDF.ref)
    setUrl(downloadUrl)
    const date = new Date().toLocaleDateString({
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    // setPdfObject({ ...form  , name: file.name , date , downloadUrl })
    // console.log('pdf' , pdfObject)
      await addDoc(collection(db, "PDF"), {
        title: form.title,
        des: form.des,
        name: file.name ,
        date ,
        downloadUrl
      });
      swal({
              title: "Good job!",
              text: "Uploaded Successfully!",
              icon: "success",
              button: "OK",
            });
      setLoading(false)
      setFile(false)

      }catch(error) {
        swal({
                title: "Error!",
                text: error.message,
                icon: "error",
                button: "OK",
              });
      setLoading(false)
      setFile(false)
      }finally{
      setLoading(false)
      setFile(false)
      }

    }else{
      swal({
        title: "Error!",
        text: 'invalid data',
        icon: "error",
        button: "OK",
      });
    }
  }
  const selectUser = async (suid) => {
    var result = await addSelectUser(suid)
  }

  useEffect(() => {
    var data = allRegisterUser()
  }, [forDependencyArray])

  // useEffect(() => {
  //   console.log('update in use effect --------->>>>' , selectedUserPdfs)
  // }, [selectedUserPdfs])

  // useEffect(() => {
  //   // console.log('machines update in useEffect ' , selectedUserMachines)
  // },[selectedUserMachines])
  
  // useEffect(() => {
  //   // console.log('pdfPopup update in useEffect ' , pdfPopup)
  // },[pdfPopup])

// ======================================== allRegisterUser ====================================== \\
const allRegisterUser = async () => {
    var result = await getUsers()
    setAllUsers(result)
}

// ======================================== allRegisterPdf ====================================== \\
const allRegisterPdf = async () => {
    var result = await getPdf()
    setAllPdf(result)
    // console.log( 'allPdf', allPdf)
}
useEffect(() => {
      const res = allRegisterPdf()
},[pdfPopup])


 // ================================ selectPDFforUser ====================================== \\
function selectPDFforUser(pdfid){
  let selectedIndex = selectedUserPdfs.indexOf(pdfid)
  //  console.log(selectedIndex)
    const copySelectedUserPdfs = [...selectedUserPdfs]
    if(selectedIndex == -1){
      
      // console.log('true')
          copySelectedUserPdfs.push(pdfid)
          setSelectedUserPdfs(copySelectedUserPdfs)
    }else{
          copySelectedUserPdfs.splice(selectedIndex , 1)
          setSelectedUserPdfs(copySelectedUserPdfs)
    }
    
}

// ================================ sendpdfstouser ====================================== \\
const sendpdfstouser = async (suid , pdfsforusr) => {
  try{
    var result = await addSelectUserPDFs(suid , pdfsforusr )
    setForDependencyArray(!forDependencyArray)
    setSelectedUserPdfs([])  
    swal({
      title: "Done!",
      text: 'PDF is selected',
      icon: "success",
      button: "OK",
    });
  }catch (e) {
    swal({
      title: "Error!",
      text: e.message,
      icon: "error",
      button: "OK",
    });
  }finally{
    setPdfPopup(false)
  }
}

// ================================ AdminPopUp ====================================== \\

useEffect(() => {
  var data = allMqttMachine()
}, [machinePopup ])

useEffect(() => {
  var data = getThisuserData()
  allMqttMachine()
  allRegisterPdf()
  // console.log('hello')
}, [deleteCondition])

const allMqttMachine = async () => {
  var result = await getRealtimeMachineData()
  setAllMachines(result)
  // console.log('allMachines----------------------',allMachines)
}


// ================================ selectMachinesforUser ====================================== \\
function selectMachinesforUser(macid){
  let selectedIndex = selectedUserMachines.indexOf(macid)
  //  console.log(selectedIndex)
    const copySelectedUserMachines = [...selectedUserMachines]
    if(selectedIndex == -1){
      
      // console.log('true')
          copySelectedUserMachines.push(macid)
          setselectedUserMachines(copySelectedUserMachines)
    }else{
          copySelectedUserMachines.splice(selectedIndex , 1)
          setselectedUserMachines(copySelectedUserMachines)
    }
    
}

// ================================ sendMachinesstouser ====================================== \\
const sendMachinestouser = async (suid , pdfsforusr) => {
  try{
    var result = await addSelectUserMachines(suid , pdfsforusr )
    setForDependencyArray(!forDependencyArray)
    setselectedUserMachines([])
    swal({
      title: "Done!",
      text: 'Machine is selected',
      icon: "success",
      button: "OK",
    });
  }catch (e) {
    swal({
      title: "Error!",
      text: e.message,
      icon: "error",
      button: "OK",
    });
  }

  
  setForDependencyArray(!forDependencyArray)
  setselectedUserMachines([])
  setMachinePopup(false)
}


// ================================================= popups ====================================== \\
// ================================ AdminPopUp ====================================== \\
async function getThisuserData(userId){
  const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      const databyuid =  docSnap.data()
      return databyuid
}

const AdminPopUp =  ({name}) => {
  function closepopup(){
    setPdfPopup(false)
    setSelectedUserPdfs([])
    setselectedUserMachines([])
    setMachinePopup(false)
    setUploadMachinePopup(false)
  }
  function getAlreaySelected(id , idArray , clickfunc , selectedArray ) {
    if(idArray ){

    let selectedIndex = idArray.indexOf(id)
    // console.log(selectedIndex , '--------')
    if(selectedIndex === -1){
      let indexInSelectedArray = selectedArray.indexOf(id)
        if(indexInSelectedArray === -1){
          return(
          <input htmlFor="defaultCheck1" type='checkbox' className='form-check-input px-2 py-1 p-md-0 mb-1'                       
            onClick={() => {
              var result = clickfunc(id) 
              }}   
            />
          )
        }else{
          return (
            <div class="form-check-input mb-2 p-0 col-1" type="checkbox" value="" id="flexCheckChecked" checked
            onClick={() => {
              var result = clickfunc(id) 
              }} 
             >
              <i class="bi bi-check-square-fill"></i>
             </div>
          )
        }
      }else{
        return (
          <i className="bi bi-check2-circle fill-green"></i>
        )
      }
    }else{
      let indexInSelectedArray = selectedArray.indexOf(id)
        if(indexInSelectedArray === -1){
          return(
          <input htmlFor="defaultCheck1" type='checkbox' className='form-check-input p-0'                       
            onClick={() => {
              var result = clickfunc(id) 
              }}   
            />
          )
        }else{
          return (
            <span class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked
            onClick={() => {
              var result = clickfunc(id) 
              }} 
             >
              <i class="bi bi-check-square-fill"></i>
             </span>
          )
        }
}

  }
  
  function deleteAlreaySelected(id , idArray , user , tupe , topicforrelod ) {
     async function deleteThis(uid , ty , tfrl){
      try{
        console.log('delete in this' , uid)
        var databyuid = await getThisuserData(uid)
        const arrayforOpratione = databyuid[ty]
        console.log('ye lo is ka data ======',databyuid)
        var getvalueIndex = arrayforOpratione.indexOf(id)
        console.log('getvalueIndex --- --- --- ',getvalueIndex)
        // var deletedItem = arrayforOpratione.slice(getvalueIndex)
        var deletedItem = arrayforOpratione.splice(getvalueIndex , 1)
        var result = await updateDoc(doc(db, "users", uid), {
          [ty] : arrayforOpratione
        });
        setDeleteCondition(!deleteCondition)
        setForDependencyArray(!forDependencyArray)
        swal({
          title: "Done!",
          text: 'item has deleted',
          icon: "success",
          button: "OK",
        });
        topicforrelod(false)
      } catch (e) {
        swal({
          title: "Error!",
          text: e.message,
          icon: "error",
          button: "OK",
        });
      } 
      // tfrl(fa)
      // alert(`${deletedItem} has deleted` )
    }
    // setDeleteCondition(!deleteCondition)
    if(idArray){
      let selectedIndex = idArray.indexOf(id)
      // console.log(selectedIndex , '--------')
      if(selectedIndex === -1){
          return
      }else{
        return (
          <div className='ms-auto p-2' onClick={() => deleteThis(user , tupe , topicforrelod)}  >
                <i className="bi bi-trash-fill text-danger"></i>
          </div>
        )
      }
    }else{
      return
    }

  }

  return(
    <div className='adminpopupcon' >
        <div id='adminpopup' className='adminpopup p-3 ' >
            <div className='popclsbtn' onClick={closepopup}  >
              <i className="bi bi-x fs-1 cls"></i>
            </div>
            <h3>Select {name}</h3>
            <ul className='p-0' >
            {name == 'PDF' ? 
                allPdf.map((i) => { 
                        return(
                <li key={i.id} className='border my-sm-2 my-3  p-sm-3 py-5 d-flex align-items-center ' >

                    {getAlreaySelected(i.id , pdfPopup.isPDF , selectPDFforUser , selectedUserPdfs)}
                
                    <span className='mx-3' >
                      name : {i.name}
                    </span>
                    <span className='mx-3' >
                      title : {i.title}
                    </span>
                    <span className='mx-3' >
                      date : {i.date}
                    </span>
                    
                    {deleteAlreaySelected(i.id , pdfPopup.isPDF , pdfPopup.uid , 'PDF' , setPdfPopup )}
                </li>
                )
              })
              :
                allMachines.map((i) => { 
                        return(
                <li key={i.id} className='border  my-sm-2 my-3  p-sm-3 py-5 d-flex align-items-center' >
                    {getAlreaySelected(i.id , machinePopup.isMachine , selectMachinesforUser , selectedUserMachines)}

                    <span className='mx-3' >
                      name : {i.id}
                    </span>
                    
                    {/* {
                    machinePopup.isMachine.indexOf(i.id) ? 
                    ''
                    :
                    <div className='ms-auto p-2' >
                      <i className="bi bi-trash-fill text-danger"></i>
                    </div>
                    } */}
                    {deleteAlreaySelected(i.id , machinePopup.isMachine , machinePopup.uid , 'Machines' , setMachinePopup)}
                </li>
                )
              })
            }
            </ul>
            <div className='row justify-content-sm-end justify-content-center px-5' >
              {name == 'PDF' ? 
                <div className='btn col-lg-4 col-sm-5 col-9 py-0 my-2' onClick={() => sendpdfstouser(pdfPopup.uid , selectedUserPdfs)} >send</div>
              :
                <div className='btn col-lg-4 col-sm-5 col-9 my-2 py-0' onClick={() => sendMachinestouser(machinePopup.uid , selectedUserMachines)} >send</div>
              }
            </div>
        </div>
    </div>
  )
}
// ================================ upload machine Topic ====================================== \\
const UpLoadTopicPopUp = () => {
  function closepopup(){
    setPdfPopup(false)
    setSelectedUserPdfs([])
    setselectedUserMachines([])
    setMachinePopup(false)
    setUploadMachinePopup(false)
    setUploadPdfPopup(false)
  }
  const uploadMacTpoics = async () => {
    if(forms.topic){
    try {
      const docRef = doc(db, "Admin", "HostPort");
        const docSnap = await getDoc(docRef);
        const webPort = await docSnap.data().port
        
    setLoading(true)
    const date = new Date().toLocaleDateString({
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
      await addDoc(collection(db, "Machines"), {
        Topic: forms.topic,
        date ,
      });
      swal({
              title: "Good job!",
              text: "Uploaded Successfully!",
              icon: "success",
              button: "OK",
            });
      setLoading(false)
      setFile(false)
      try{
        
        if (docSnap.exists()) {
          // console.log("Document data:", webPort);
          var condition = true
          axios.post(`${webPort}/addNewTopicForSubs`,{
            condition
          }).then(() => 
          console.log('topic uploaded')
          )
          .catch(() =>
          console.log('topic not uploaded')
          )
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
        
      }catch(err){
        console.log( 'error in post request to backend', err )
      }

      }catch(error) {
        swal({
                title: "Error!",
                text: error.message,
                icon: "error",
                button: "OK",
              });
      setLoading(false)
      }finally{
      setLoading(false)
      }

    }else{
      swal({
        title: "Error!",
        text: 'invalid data',
        icon: "error",
        button: "OK",
      });
    }
  }

  
  return(
    <div className='adminpopupcon' >
        <div className='adminpopup p-3' >
            <div className='popclsbtn' onClick={closepopup}  >
              <i className="bi bi-x fs-1 cls"></i>
            </div>
            <h3>Upload Machines Topic Name</h3>
            <div className="mb-3">
                <label htmlFor="exampleInputPassword5" className="form-label fs-5">Title</label>
                <input type="text"   className="form-control border border-2" id="exampleInputPassword5" onChange={e =>  updateMachinesForm(e, 'topic')}/>
            </div>

           <div className='row justify-content-sm-end justify-content-center px-5' >
               <div className='btn col-lg-4 col-sm-5 col-9 py-0  my-2' onClick={() => uploadMacTpoics()} >Upload</div>
           </div>
        </div>
   </div> 
    )
}
// ================================ Upload Pdfs PopUp ====================================== \\
const UploadPdfsPopUp = () => {
  function closepopup(){
    setPdfPopup(false)
    setSelectedUserPdfs([])
    setselectedUserMachines([])
    setMachinePopup(false)
    setUploadMachinePopup(false)
    setUploadPdfPopup(false)
  }


  return(
    <div className='adminpopupcon' >
      
    <form className="adminpopup p-4" >
            <div className='popclsbtn' onClick={closepopup}  >
              <i className="bi bi-x fs-1 cls"></i>
            </div>
            
    <div className='row' >
        <h1 className='text-center' >Upload pdf</h1>
    </div>
    <div className="mb-3">
      <label htmlFor="exampleInputPassword1" className="form-label fs-5">Title</label>
      <input type="text"  className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'title')}/>
    </div>
  <div className="mb-3">
      <label htmlFor="exampleInputPassword1" className="form-label fs-5">Description</label>
      <input type="text" className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'des')}/>
  </div>
  <div className="mb-3">
      <label className='btn btn-success col-lg-3 col-sm-4 col-5 mx-1 py-0'  >
        <input  type='file' required   name='pdf' accept='application/pdf,applic ation/vnd.ms-excel' className='d-none' onChange={(e) => setFile(e.target.files[0])} /> 
        select file
      </label>
  </div>
  <div className='row justify-content-end px-3 my-4' >
    {loading ? <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      : 
      <label className='btn btn-success col-lg-4 py-0 col-sm-5 col-5 mx-1 ' onClick={uploadPdf}  >
        <i className="bi bi-upload"></i> Upload PDF
      </label>
      }
    </div>
</form>
    </div>
  )
}

  return (
    <div className='container-fluid px-3' >
      {pdfPopup ? <AdminPopUp name='PDF'  /> : '' }
      {machinePopup ? <AdminPopUp name='Machines' /> : ''}
      {uploadMachinePopup ? UpLoadTopicPopUp() : ''}
      {uploadPdfPopup ? UploadPdfsPopUp() : ''}
        <div className='row px-sm-5 border justify-content-center' >
          <div className='row my-3 justify-content-center' >
              <div className='btn btn-success col-xl-2 col-lg-3 col-md-4 col-sm-6 col mx-1 py-0 my-2 my-sm-0' onClick={() => setUploadMachinePopup(true)}
                >Upload Machine Topic</div>
                <div className='btn btn-success col-lg-2 col-md-3 col-sm-4 col mx-1 my-2 py-0 my-sm-0  ' onClick={() => setUploadPdfPopup(true)}
                >Upload PDf </div>
              <Link to="/ContactEmails" className='btn btn-success col-lg-2 col-md-3 py-0 col-sm-4 col my-md-0 my-2 mx-1' >
                  Contact Emails
              </Link>
            </div>          
        </div>

 
        <div className='row px-3 table-row ' >
        <table className="table table-success table-hover table-striped">
        <thead>
          <tr>
            <th scope="col" className='col-1'>
              {/* <input   type='checkbox' className='form-check-input p-0 d-none' />
              <label className='form-check-label'  htmlFor="defaultCheck1"></label> */}
            </th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            <th scope="col">Organization</th>
            <th scope="col">Date</th>
            <th scope="col">PDF</th>
            <th scope="col">Machines</th>
          </tr>
        </thead>
        {allUsers?
          <tbody>
          {allUsers.map((user) => { 
            return(
            <tr key={user.uid} className={user.selected ? 'table-active' : ''} >
              <th scope="row">
                {user.selected && user.PDF || user.Machines ?
                <i className="bi bi-check2-circle fill-green"></i>
                :
                <input htmlFor="defaultCheck1" type='checkbox' className='form-check-input p-0' onClick={() => selectUser(user.uid)}  /> 
                }
              </th>
              <td scope="col">{user.firstName}</td>
              <td scope="col">{user.lastName}</td>
              <td scope="col">{user.email}</td>
              <td scope="col">{user.organization}</td>
              <td scope="col">{user.date}</td>
              <td scope="col" className='selbtn p-3'  onClick={(e) => setPdfPopup({uid : user.uid , isPDF : user.PDF})  } >select</td>
              <td scope="col" className='selbtn ' onClick={(e) => setMachinePopup({uid : user.uid , isMachine: user.Machines })} >select</td>
            </tr>
            )
          })}
          </tbody>
          :
          console.log(allUsers)
          
        }
        </table>
        </div>
    </div>
  )
}



export default AdminPanel