import React , { useState } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import { SignIn } from '../config'
import swal from 'sweetalert'
import LogInForm from '../Components/LogInForm'

const LogIn = () => {
  // const [ form, setForm ] = useState()

  const navigate = useNavigate('/')
  const signIn = async (form) => {
    const { email, password } = form
    try {
    const result = await SignIn(email, password)
      navigate('/')
    }catch(e){
        swal({
            title: 'Error',
            text: 'invalid email or password',
            icon: 'error',
            button: 'Ok'
        })
    }
  }

  // const updateForm = (e, key) => {
  //   setForm( {...form, [key] : e.target.value})
  // }

  return (

    <LogInForm
    Formheading='Log In'
    submitFunction={signIn}
    />

  )
}

export default LogIn