import React, { useEffect , useState , useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Badge, Button, Form } from "react-bootstrap";
import { getUserInfo } from '../../config'
import header from "../../utils/header";
import '../../css/animate.min.css';
import '../../css/demo.css';  
import '../../css/dashboard1.css';
// import '../../css/light-bootstrap-dashboard-react.css.map';
// import '../../css/light-bootstrap-dashboard-react.min.css';
import { Nav } from "react-bootstrap";
import logo from '../../assets/images/logo.png'
import sidebarImage from "../../assets/img/sidebar-3.jpg";
import sideBarImage1 from "../../assets/img/sidebar-1.jpg";
import sideBarImage2 from "../../assets/img/sidebar-2.jpg";
import sideBarImage3 from "../../assets/img/sidebar-3.jpg";
import sideBarImage4 from "../../assets/img/sidebar-4.jpg";
import badgeColors from "../../utils/badgeColors";
import { useLocation , Link } from "react-router-dom";

function Sidebar({  allMachinesres , getmacno , sideBarState , setSlideBarState , headno }) {
const [allMachines , setAllMachines] = useState(false)
const [image, setImage] =useState(sidebarImage);
const [color, setColor] =useState("black");
const [hasImage, setHasImage] =useState(false);
const [allmacno , setAllmcno] =useState()
const [allmacnoDetailPage , setAllmcnoDetailPage] = useState()
const [settingOn , setSettingOn] =useState(false)
// const [sideBarClass , setSideBarClass] = React.useState('')
const [userData, setUserData] = useState()
const [filterByStatus, setFilterByStatus] = useState()
const [filterByStatusArr, setFilterByStatusArr] = useState()


// =- set active machine -= \\
// useEffect(()=>{
//   setallmacno()
// },[allmacnoDetailPage])

// function setallmacno(){
//   // console.log('allmacnoDetailPage == '+ allmacnoDetailPage)
//   if(allmacnoDetailPage == 'Dashboard'){
//     getmacno({allmacno , allmacnoDetailPage})
//   }else if(allmacnoDetailPage == 'Recipeviewer'){
//     getmacno({allmacno , allmacnoDetailPage })
//   } 
//   else{
//     // getmacno(0)
//     return
//   }
//   setAllmcnoDetailPage('')
//   setAllmcno(false)
//   setSlideBarState(false)
// }

// ===================
const sideBarClass = useMemo(() => {
  if(sideBarState == true){
    return 'sidebarOpen'
  }else{
    return ''
  }
}, [sideBarState]);
// function setSideBarCurrentState(sb){
//   if(sb == true){
//     setSideBarClass('sidebarOpen')
//   }else{
//     setSideBarClass('')
//   }
// }

// useEffect(()=>{
//   setSideBarCurrentState(sideBarState)
// },[sideBarState])


//================


const receiveUserData = async () => {
  const result = await getUserInfo()
  setUserData(result)
}


useEffect( () => {
  const res = receiveUserData()
  // console.log('result------  ------' , userData , '------  -------result')
}, [])

//============

function updateAllMachines(allM){
  // setAllMachines(allM)
    setAllMachines(allM);
}

const allMachinesMemoized = useMemo(() => {
  if (allMachinesres?.length > 0) {
    return [...allMachinesres].sort((a, b) => a.id - b.id);
  }
}, [allMachinesres]);


useEffect(() => {
  updateAllMachines(allMachinesMemoized)
},[allMachinesMemoized])


// useEffect(() => {
//   console.log('allMachines =========== >>>' , allMachines )
// },[allMachines])

// useEffect( () => {
//   console.log('allmacno------  ------' , allmacno , '------  -------allmacno')
// }, [allmacno])

useEffect(() => {
  if (allmacnoDetailPage === 'Dashboard' || allmacnoDetailPage === 'Recipeviewer') {
    getmacno({ allmacno, allmacnoDetailPage });
    setAllmcnoDetailPage('');
    setAllmcno(false);
    setSlideBarState(false);
  }
}, [allmacno, allmacnoDetailPage, getmacno, setSlideBarState]);


const setthemeColor = (color , alt) => {
  const root = document.documentElement;
  if(alt){
    root.style.setProperty('--sidearrow', color );
    root.style.setProperty('--theme', alt);
    setColor(color)
    return
  }
  root.style.setProperty('--theme', color);
  root.style.setProperty('--sidearrow', color);
  setColor(color)
}
const location = useLocation();
 
async function getfilterByStatus(stat){
  if(filterByStatus == stat){
     setFilterByStatus()
  }else{
    setFilterByStatus(stat)
  }
}

  return (
    <div className={"sidebar  " +  sideBarClass} data-image={image} data-color={color}>
    
      <div // For Background Image 
        className="sidebar-background"
              style={hasImage ? 
                
                {backgroundImage: `url( ${image} )` }
        : {backgroundImage : "none"} }
      />
      
      { // Buttons on middle and small devices
        sideBarState ?  
        <div className=" togglebtn d-lg-none d-block py-3 pe-3" onClick={() => {
          setSlideBarState(false)
        }}  >
          {/* <i className="bi bi-chevron-double-left m-0 fs-6"></i> */}
          <div className="divopen" ></div>
        </div>
        :
        <div className=" togglebtn d-lg-none d-block py-3 pe-3" onClick={() => {
                setSlideBarState(true)
              }}  >
            {/* <i className="bi bi-chevron-double-right m-0 fs-6"></i> */}
            <div className="divclose" ></div>
        </div>
      }

      <div className="sidebar-wrapper  overflow-y-hidden py-1 ">
        <div className="logo-conatiner logo d-flex align-items-center justify-content-center py-1 pz-0 ">
            {
              userData ? 
              // <div className="dashboardSideBarlogo " style={{backgroundImage: `url(${userData.profilePic})`}}>
              // </div>
              <div className="profilecon py-1 pe-1" >
                {
                  userData.profilePic ?
                  <img src={userData.profilePic} alt='profile' className="img-fluid dashboardSideBarlogo  " /> :
                  ''
                }
                
              <div className="organization :" >{userData.organization}</div>
              </div>     
              :
              ''
            }
        </div>
        {
          settingOn ? 
            // Setting section
            <div className="overflow-auto container "  >
              <div className="d-flex align-items-center my-3 justify-content-between">
                <p className="align-self-center mb-0" >Background Image</p>
                <Form.Check
                  type="switch"
                  id="custom-switch-1-image"
                  checked={hasImage}
                  onChange={() => setHasImage(!hasImage)}
                />
              </div>
              <div className="d-flex flex-column  my-3">
                <p className="my-2" >Filters</p>
                <div className="row justify-content-evenly py-3 setcolorContainer">
                {
                  badgeColors.map((item , index) => {
                    return(
                    <span
                      key={index}
                      style={{backgroundColor : item.clr}}
                      variant={item.clr}
                      className={color == item.clr ? "act badge" : "badge"}
                      onClick={() =>{item.alt ? setthemeColor(item.clr , item.alt) :
                      setthemeColor(item.clr)
                        }}
                    ></span>
                    )
                  })
                }
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="header-title">Sidebar Images</div>
              <div className="row py-4 justify-content-evenly dashboardSelectBackImg pe-1" >
                        <div className={image === sideBarImage1 ? "active col-5 p-0 my-1  " : "col-5 p-0 my-1 "}>
                          <a
                            className="img-holder switch-trigger d-block"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(sideBarImage1);
                            }}
                          >
                            <img alt="..." src={sideBarImage1}  className="img-fluid "></img>
                          </a>
                        </div>
                        <div className={image === sideBarImage2 ? "active col-5 p-0 my-1 " : "col-5 p-0 my-1 "}>
                          <a
                            className="img-holder switch-trigger d-block"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(sideBarImage2);
                            }}
                          >
                            <img alt="..." src={sideBarImage2} className="img-fluid" ></img>
                          </a>
                        </div>
                        <div className={image === sideBarImage3 ? "active col-5 p-0 my-1 " : " col-5 p-0 my-1 "}>
                          <a
                            className="img-holder switch-trigger d-block"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(sideBarImage3);
                            }}
                          >
                            <img alt="..." src={sideBarImage3} className="img-fluid " ></img>
                          </a>
                        </div>
                        <div className={image === sideBarImage4 ? "active col-5 p-0 my-1 " : " col-5 p-0 my-1 "}>
                          <a
                            className="img-holder switch-trigger d-block"
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(sideBarImage4);
                            }}
                          >
                            <img alt="..." src={sideBarImage4} className="img-fluid " ></img>
                          </a>
                        </div>
              </div>    
            </div>
            :
            <> 
            {  headno ? // machines option on Dashboard Page only
              <Nav className="dashboardSideBarMacNoContainer pb-5  " >
                    <div className="my-1" >
                      <button className={`sidebar-filter-btn px-3 rounded-pill mx-1 ${filterByStatus == "online" ? "sidebarActive" : ''}`} onClick={()=>getfilterByStatus("online")} > online </button>
                      <button className={`sidebar-filter-btn px-3 rounded-pill mx-1 ${filterByStatus == "offline" ? "sidebarActive" : ''}`} onClick={()=>getfilterByStatus("offline")} > offline </button>
                    </div>

                    {
                      allMachines && filterByStatus  ?
                      allMachines?.filter(item => item.data.status == filterByStatus)?.map((item , index) => {
                        // console.log('allMachinesres at render time' , allMachinesres)
                        // console.log('allMachinesres item at render time index' , index)
                            
                        return(
                          //   <div className='dashboardsideBarItem ' onClick={e => {
                          //     setAllmcno(item.id) 
                          // }} key={item.id} >{item.id}</div>
                          <li
                            className={allmacno == item.id ? "active" : ''}
                            key={item.id}
                              
                          >
                            {allmacno == item.id ?
                            <div
                              onClick={e => {
                                setAllmcno(false) 
                              }}
                              className="nav-link d-flex justify-content-between px-1"
                            >
                              {
                                item.data.status === 'online'
                                 ? 
                                  <i className="bi bi-dot fs-3 text-success"></i>

                                :
                                  <i className="bi bi-dot fs-3  opacity-50"></i>
                              }
                              <p className="fs-5 fw-normal opacity-100" >{item.id}</p>
                              <div className="d-flex opacity-75" >
                                <i className="fa-solid fa-chevron-up fs-6 align-self-center"></i>
                              </div>
                            </div>
                              :
                            <div
                              onClick={e => {
                                setAllmcno(item.id) 
                              }}
                              className="nav-link d-flex justify-content-between px-1"
                            >
                              {
                                item.data.status === 'online'
                                 ? 
                                  <i className="bi bi-dot fs-3 text-success"></i>

                                :
                                  <i className="bi bi-dot fs-3  opacity-75"></i>
                              }
                              <p className="fs-5 fw-normal opacity-75" >{item.id}</p>
                              <div className="d-flex opacity-50" >
                                <i className="fa-solid fa-chevron-down fs-6 align-self-center"></i>
                              </div>
                            </div>
                            }
                            <div className={ allmacno == item.id ? "nav-link text-center sidebarmacdropdown opensidebaritem " :
                            ' sidebarmacdropdown my-0'} >
                              <div onClick={() => {
                                setAllmcnoDetailPage('Dashboard')
                              }} className="py-2 fs-6 fw-normal opacity-75 b-light my-2" >Dashboard</div>
                              <div onClick={() => {
                                setAllmcnoDetailPage('Recipeviewer')
                              }} className="py-2 fs-6 fw-normal opacity-75 b-light my-2" >Recipe Viewer</div>
                              
                            </div>
                            
                          </li>
                          )
                    })
                      :  allMachines ? 
                        allMachines.map((item , index) => {
                            // console.log('allMachinesres at render time' , allMachinesres)
                            // console.log('allMachinesres item at render time index' , item.id)
                                
                            return(
                              //   <div className='dashboardsideBarItem ' onClick={e => {
                              //     setAllmcno(item.id) 
                              // }} key={item.id} >{item.id}</div>
                              <li
                                className={allmacno == item.id ? "active" : ''}
                                key={item.id}
                                  
                              >
                                {allmacno == item.id ?
                                <div
                                  onClick={e => {
                                    setAllmcno(false) 
                                  }}
                                  className="nav-link d-flex justify-content-between px-1"
                                >
                                  {
                                    item.data.status === 'online'
                                     ? 
                                      <i className="bi bi-dot fs-3 text-success"></i>

                                    :
                                      <i className="bi bi-dot fs-3  opacity-50"></i>
                                  }
                                  <p className="fs-5 fw-normal opacity-100" >{item.id}</p>
                                  <div className="d-flex opacity-75" >
                                    <i className="fa-solid fa-chevron-up fs-6 align-self-center"></i>
                                  </div>
                                </div>
                                  :
                                <div
                                  onClick={e => {
                                    setAllmcno(item.id) 

                                  }}
                                  className="nav-link d-flex justify-content-between px-1"
                                >
                                  {
                                    item.data.status === 'online'
                                     ? 
                                      <i className="bi bi-dot fs-3 text-success"></i>

                                    :
                                      <i className="bi bi-dot fs-3  opacity-75"></i>
                                  }
                                  <p className="fs-5 fw-normal opacity-75" >{item.id}</p>
                                  <div className="d-flex opacity-50" >
                                    <i className="fa-solid fa-chevron-down fs-6 align-self-center"></i>
                                  </div>
                                </div>
                                }
                                <div className={ allmacno == item.id ? "nav-link text-center sidebarmacdropdown opensidebaritem " :
                                ' sidebarmacdropdown my-0'} >
                                  <div onClick={() => {
                                    setAllmcnoDetailPage('Dashboard')
                                  }} className="py-2 fs-6 fw-normal opacity-75 b-light my-2" >Dashboard</div>
                                  <div onClick={() => {
                                    setAllmcnoDetailPage('Recipeviewer')
                                  }} className="py-2 fs-6 fw-normal opacity-75 b-light my-2" >Recipe Viewer</div>
                                  
                                </div>
                                
                              </li>
                              )
                        })
                          : 
                          <div className='dashboardsideBarItem placeholder-glow' >
                              <span className="placeholder col-8 p-2 placeholder"></span>
                              <span className="placeholder col-6 p-2 placeholder"></span>
                              <span className="placeholder col-9 p-2 placeholder"></span>
                              <span className="placeholder col-6 p-2 placeholder"></span>
                          </div>
                      }
              </Nav>
              :
              // Pages Routes except Dashboard Page
              <Nav className=" pb-5  " >
                  {header.menus.map((item, index) => {
                      return (
                        <li
                          className={
                            location.pathname === item.id
                              ? "active "
                              : ''
                          }
                          key={item.id}
                        >
                          <Link 
                          to={item.id}
                          className="nav-link"
                            // activeClassName="active"
                          >
                            <i className={item.icon} />
                            <p>{item.title}</p>
                          </Link>
                        </li>
                      );
                  })}
              </Nav>
              
            }
            </>
        }     
        <Nav>
          <li
          className="active active-pro dashboardSettingBtn"
          onClick={e => {
            setSettingOn(!settingOn)
          }}
          >

              </li>
        </Nav>     
      </div>
    </div>
  );
}

export default Sidebar;
