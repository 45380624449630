import React , { useState } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import { userRegister , uploadImage , updateProfile } from '../config';


const SignUp = () => {
  const [form, setForm] = useState();
  const navigate = useNavigate()
  const [imageURI, setImageURI] = useState(false)
  const formVerify = () => {
    if(form.firstName && form.lastName && form.organization && form.email && form.password && imageURI  ){
      // console.log(form,'------form')
      return true
    }else{
      // console.log(form,'------form in else')
      return false
    }
  } 

  const signUp = async () => {
    // console.log(form)
    if(formVerify()){
    console.log('formverify')

      try {
    console.log('form in try')

      const result = await userRegister(form)
      const url = await uploadImage(imageURI)
      await updateProfile({ profilePic: url })
        await swal({
              title: "Congratulations!",
              text: "Your Account Registered Successfully!",
              icon: "success",
              button: "OK",
        });
         navigate('/')
      }catch (e) {
        console.log(e.message)
          swal({
              title: "Error!",
              text: e.message,
              icon: "error",
              button: "OK",
            });
      }
    }else{
      swal({
        title: "Error!",
        text: 'Please fill in all the fields',
        icon: "error",
        button: "OK",
      });
    }
}

  const updateForm = (e, key) => {
    // console.log(imageURI)

    setForm({ ...form, [key]: e.target.value });
  };

  return (
    <section className="loginSection d-flex justify-content-center align-items-center container py-5" >
        <form className="border p-md-5 p-4  pt-3" >
              <div className="form-heading">
                <h1 className="text-center"> Register </h1>
              </div>
            <div className="row mt-4 mb-3">
                <div className="col-lg-6 my-lg-0 my-2" >
                    <label htmlFor="exampleInputEmail1" className="form-label fs-5 ">First Name</label>
                    <input type="text" className="form-control" id="exampleInputText1" 
                    aria-autocomplete='true'aria-describedby="emailHelp" onChange={ (e) => updateForm(e, 'firstName') } />
                </div>
                <div className="col-lg-6 my-lg-0 my-2" >
                    <label htmlFor="exampleInputEmail1" className="form-label fs-5 ">Last Name</label>
                    <input type="text" className="form-control" id="exampleInputText2"
                    aria-autocomplete='true' aria-describedby="emailHelp" onChange={ (e) => updateForm(e, 'lastName') } />
                </div>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col-lg-7 col-md-7 my-lg-0 my-2">
                  <label htmlFor="exampleInputEmail1" className="form-label fs-5 ">Organization</label>
                  <input type="text" className="form-control" id="exampleInputText3" aria-autocomplete='true' aria-describedby="emailHelp" onChange={ (e) => updateForm(e, 'organization') } />
              </div>
              <div className='col-lg-4 col-md-5 col-6 my-lg-0 my-2' >
                <label htmlFor="exampleInputEmail1" className="form-label fs-5">Organization Logo </label>
                <input className='myinput4 py-1' name='photos' accept='image/png , image/jpeg , image/jpg' type="file" onChange={(e) => setImageURI(e.target.files[0])}/>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label fs-5 ">Email Address</label>
              <input type="email" autoComplete='username' className="form-control" id="exampleInputEmail1" aria-autocomplete='true' aria-describedby="emailHelp" onChange={ (e) => updateForm(e, 'email') } />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" aria-autocomplete='true' className="form-label fs-5 ">Password</label>
              <input type="password" autoComplete='current-password' className="form-control" id="exampleInputPassword1" onChange={ (e) => updateForm (e, 'password') }/>
            </div>
            <div className="row py-3 justify-content-sm-between justify-content-center  align-items-center">
                <div className="col-md-7 col-sm-8 col-12 " >
                   <Link to="/LogIn" className='my-green' > I already have an account </Link>
                </div>
                <div className="border-0 col-sm-3 col-sm-4 col-5 my-sm-0 my-2 text-center me-sm-3 py-2 submit-btn" onClick={ signUp } >Register</div>
            </div>
            
        </form>
    </section>


  )
}

export default SignUp