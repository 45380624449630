import React , { useState , useEffect } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { getContactForms } from '../config'

const ContactEmails = () => {
  const [allContact, setAllContact] = useState()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    var data = allContactForm()
  }, [])

  const allContactForm = async () => {
    var result = await getContactForms()
    setAllContact(result)
  }

  return (
    <div className='ContactEmailsContainer container py-5 ' >
        <div className='row' >
            <h1 className='text-center' >Contact Emails</h1>
        </div>
        {allContact ?
          allContact.map((item) => { 
            return(
            <div key={item.id} className='Cemail row p-3 my-1 align-items-center' onClick={e => navigate(`/ContactEmails/${item.id}`) } >
              <div className="col">From: {item.email}</div>
              <div className="col-md-4 col-sm-5">Date: {item.date}</div>
            </div>
            )
          })
          :
          console.log(allContact)
        }
    </div>
  )
}

export default ContactEmails