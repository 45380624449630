import Header from './Components/Header';
import Home from './Pages/Home';
import About from './Pages/About';
import Footer from './Components/Footer';
import SignUp from './Pages/SignUp';
import LogIn from './Pages/LogIn';
import Product from './Pages/Product';
import AdminPanel from './Pages/AdminPanel';
import AdminPanelLogIn from './Pages/AdminPanelLogin';
import Profile from './Pages/Profile';
import ForgotPassword from './Pages/ForgotPassword';
import { Routes , Route } from 'react-router-dom';
import { useState , useEffect} from 'react';
import { auth , onAuthStateChanged , getUserInfo , getAdmin , getMachineData } from './config';
import Contact from './Pages/Contact';
import ContactEmails from './Pages/ContactEmails';
import ContactDetail from './Pages/ContactDetail';
import Dashboard from './Pages/Dashboard';
import { Provider , useSelector , useDispatch } from 'react-redux';
import { addUserFromLocal , removeUserFromLocal } from './store/actions/userAction';
import { store } from './store/index';
import PageNotFound from './Pages/PageNotFound';
import Layout from './layout';
import { useLocation } from 'react-router-dom';
import header from './utils/header';
import CookieConsent from "react-cookie-consent";
import ChartistGraph from 'react-chartist';
// import { getDataForSpeedGraph } from './config';


function App() {
  const [ user , setUser ] = useState();
  const [adminData, setAdminData] = useState(false)
  const [machineExist , setMachineExist] = useState(true) 
  const [admin , setadmin] = useState() 

  useEffect( () => {
  onAuthStateChanged(auth, (isUser) => {
    if(isUser){
      var userInfo =  receiveUserData()
      if(user){
        if(user.Machines || user.Machines.length === 0){
            setMachineExist(false)
        }else{
          setMachineExist(user.Machines)
        }
      }else{
          setMachineExist(false)
      }
      setadmin(isUser.uid)
      // dispatch(addUserFromLocal(isUser))
      // addUserFromLocal(isUser.uid)
    }else{
      setUser(false)
      setadmin(false)
      // dispatch(removeUserFromLocal())
      // removeUserFromLocal()
    }
  });
  },[])

  const receiveAdminData = async () => {
      const result = await getAdmin()
      setAdminData(result.uid)
  }
  const receiveUserData = async () => {
    const result = await getUserInfo()
    setUser(result) 

}
  useEffect(() => {
    const adminres = receiveAdminData()
  },[])

useEffect(() => {
    if(user){
        if(user.Machines && user.Machines.length !== 0){
          setMachineExist(user.Machines)
        }else{
          setMachineExist(false)
        }
    }else{
        setMachineExist(false)
    }
},[user])

  const protectedRoute = (item,e) => {
    const result = user ? item : e
    return result
  }

  const protectedAdminRoute = (item,e) => {
    if(user && admin === adminData ){
      if(user.uid){
        const result = user.uid === adminData ? item : e
        return result
      }else{
        return item
      }
    }else{
      return e
    }
  }

  const location = useLocation();
    var headno = false
  // Check if the current route is '/dashboard'
  const isDashboardRoute = location.pathname === '/Dashboard';
  
  // Conditional rendering of the header
  if (isDashboardRoute) {
     headno = true // Don't render header for the dashboard route
  }
  

  return (
    <Provider store={store}>
    <div className="App">
      <div className='mainContainer container-fluid p-0 position-relative' >
        
        {
          headno ? 
          ''
          :
          <Header data={header} userState={user} />
          
        }
          
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/Product" element={<Product/>} />
          <Route path="/Contact" element={<Contact/>} />
          <Route path="/SignUp" element={protectedRoute(<Home/>,<SignUp/>)} />
          <Route path="/LogIn" element={protectedRoute(<Home/>,<LogIn/>)} />
          <Route path="/ForgotPassword" element={protectedRoute(<Home/>,<ForgotPassword/>)} />
          <Route path="/Profile" element={protectedRoute(<Profile/>,<LogIn/>)} />

          {
            machineExist &&
            <Route path="/Dashboard" element={protectedRoute( <Layout user={user} protectedAdminRoute={protectedAdminRoute}  /> ,<LogIn/>)} />
          }
          <Route path="/AdminPanel" element={protectedAdminRoute(<AdminPanel/>,<AdminPanelLogIn/>)} />
          <Route path="/ContactEmails" element={protectedAdminRoute(<ContactEmails/>,<AdminPanelLogIn/>)} />
          <Route path="/AdminPanelLogIn" element={<AdminPanelLogIn/>}/>
          <Route path="/ContactEmails/:contactId" element={protectedAdminRoute(<ContactDetail/>,<AdminPanelLogIn/>)}/>
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
        <Footer />
        <CookieConsent
        debug={true}
        location="bottom"
        style={{ background: "#2B373B", textAlign: "center" }}
        buttonStyle={{ background: '#8F996B', color: "#FFF", fontSize: "13px" }}
        buttonText="I Understand."
        expires={31}
        >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>
      </div>
    </div>
    </Provider>


  );
}


export default App;



