import {useEffect , useState} from 'react'
import {
    Card,
    Row,
    Col
} from "react-bootstrap";
import Timeconverter from './Timeconverter';

const CurrentRecipe = ({data : {Payload , timestamp}}) => {
    const [temperatures, setTemperatures] = useState(false)

    function setvalues(temp){
        setTemperatures(temp)
    }
        
    useEffect(() => {
        setvalues(Payload)
    },[Payload])

    const AllChildTopicsData = () => {
        if(temperatures ){
          // console.log('alldata------',allMachinesres)
          var Alldetail = []
        //   for (const [key, value] of Object.entries(temperatures)) {
            var Payloadvalues = []
            var PayloadKeys = []

            // console.log(key , value.Payload)
            for (const [subkey, subvalue] of Object.entries(temperatures)) {
            //   console.log(subkey , subvalue)
              var sval = subkey.toLowerCase()
              var unit 
              if(sval == 'set speed'){
                unit = '(PPM)'
              }else{
                unit = '(mm)'
              }

            if(sval === 'recipe name' || sval === 'runnig recipe'){
                
                Payloadvalues.unshift(<li className='py-1 ' key={`${subkey}&&${subvalue}`} >{subvalue} </li>)
                PayloadKeys.unshift(<li className='py-1' key={`${subvalue}&Key${subkey}`} >{subkey} :</li>)
            }
            else{
                
                Payloadvalues.push(<li className='py-1 ' key={`${subkey}&&${subvalue}`} >{subvalue} {unit}</li>)
                PayloadKeys.push(<li className='py-1' key={`${subvalue}&Key${subkey}`} >{subkey} :</li>)
            }
            }
           
        //   }
          Alldetail.unshift(
            <div className='d-flex justify-content-between' key={`keyCard${PayloadKeys}No&${Payloadvalues}`} >
              <ul className="icon-big text-start card-category  ultynone">
                    {PayloadKeys}
                </ul>
                {/* </Col>
                <Col lg="3" md="4" xs="3" className='px-0'> */}
                <ul className="numbers ultynone card-category py-1"> 
                    {Payloadvalues}
                </ul>
            </div>
            // <Row className='py-2' key={`keyCard${PayloadKeys}No&${Payloadvalues}`} >
            //     <Col lg="9" md="8" xs="9" className='px-0'>
            //     <ul className="icon-big text-start card-category  ultynone">
            //         {PayloadKeys}
            //     </ul>
            //     </Col>
            //     <Col lg="3" md="4" xs="3" className='px-0'>
            //     <ul className="numbers ultynone card-category py-1"> 
            //         {Payloadvalues}
            //     </ul>
            //     </Col>
            // </Row>
        )
          return(
            Alldetail
          )
        }else{
          return 'loading'
        }
    }


    
  return (
    <Col className='pe-0 mb-xl-0 mb-3' md="6">
    <Card className="card-stats">
        <Card.Header>
        <Row className='justify-content-between align-items-center text-end ' >
            <Col xs="3">
            <div className="icon-big text-center icon-info">
                <i className="bi bi-journal-text text-info"></i>
            </div>
            </Col>
            <Col xs="6">
            <div className="numbers"> 
                <Card.Title as="h4">Current Recipe</Card.Title>
                {/* <p className="card-category fs-4"><sup className='fs-2 fw-bolder' >.</sup>C</p> */}
                
            </div>
            </Col>
        </Row>
        <hr></hr>

        </Card.Header>
        <Card.Body>
        <Row>
            <AllChildTopicsData/>
        </Row>
        </Card.Body>
        {timestamp ?
                <Timeconverter DateNtime={timestamp}/>
            : '' }
        
    </Card>
    </Col>
  )
}

export default CurrentRecipe