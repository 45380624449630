import React, { useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import { auth } from '../config';
import { sendPasswordResetEmail } from "firebase/auth";
import swal from 'sweetalert';
function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate()
     
  const [ form, setForm ] = useState()
  const updateForm = (e, key) => {
    setForm(e.target.value)
  }
  const handleResetPassword = async () => {
    try {
      var result = await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
      swal({
        title: "Done!",
        text: "Password reset email sent. Please check your inbox.",
        icon: "success",
        button: "OK",
      });
      navigate('/LogIn')
    } catch (error) {
      // setMessage(error.message);
      swal({
        title: "Error!",
        text: `Error : ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };

  return (
    <section className="ForgottenSection d-flex justify-content-center align-items-center container ">
        <form className="my-3 border p-md-3 p-3 " >
            <div className="form-heading">
              <h1 className="text-center fs-3 mb-3 pb-0">Forgot Password</h1>
            </div>
            <div className="my-5">
              <label htmlFor="exampleInputEmail1 aria-autocomplete='true'" className="form-label fs-5 ">Email address</label>
              <input type="email" autoComplete='username' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
              onChange={(e) => setEmail(e.target.value)}
              required/>
            </div>
            <div className="d-flex justify-content-between   align-items-center my-3" >
                <div className="border-0  me-0 py-2 text-center" >
                  <Link to="/LogIn" className='my-green' >login with Password</Link>
                </div>
                <div className="border-0 col-md-3 col-sm-4 col-5 py-2 text-center submit-btn" onClick={handleResetPassword} >send</div>
            </div>
            {/* {message && <p>{message}</p>} */}

        </form>
    </section>
  );
}

export default ForgotPassword;


