import React from 'react'

const Slider = ({si1 , si2 , si3}) => {
  return (
    <section className="sliderSection" >
			<div id="carouselExample"  className="carousel slide ">
				<div className="carousel-inner ">
				  <div className="carousel-item active">
					<img src={si1} className="slide-img " alt="Slider image"/>
				  </div>
				  <div className="carousel-item">
					<img src={si2} className="slide-img " alt="Slider image"/>
				  </div>
				  <div className="carousel-item">
					<img src={si3} className="slide-img " alt="Slider image"/>
				  </div>
			</div>
			<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
			  <span className="carousel-control-prev-icon carousel-btns " aria-hidden="true"></span>
			  <span className="visually-hidden">Previous</span>
			</button>
			<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
			  <span className="carousel-control-next-icon carousel-btns" aria-hidden="true"></span>
			  <span className="visually-hidden">Next</span>
			</button>
		  </div>
	</section>
  )
}

export default Slider