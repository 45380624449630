import React from 'react'
import pi1 from '../assets/images/product/p1.jpg' 
import pi2 from '../assets/images/product/p2.jpg' 
const Product = () => {
  return (
    <>
        <section className="container py-md-5 py-3 px-2" >
            <div className="row justify-content-center mb-md-5">
                <h1 className="col-md-8 col-sm-10 col-11 text-center fw-light fs-md-2 fs-1 " >
                    OUR PRODUCTS
                    <strong> CREATE SOLUTIONS </strong>
                </h1>
            </div>
        </section>

        <section className="container py-lg-5">
        <div className="row px-5 my-5 justify-content-lg-evenly justify-content-center align-items-center text-lg-start text-center">
                <div className="col-lg-5 col-md-9 col-11">
                    <h1 className="fs-2 text-lg-start text-center" >
                        <strong> ELECTRICAL INSTALLATION </strong>
                    </h1>   
                    <p className="fs-6 my-5" >
                        The installation systems from Wieland Electric, gesis® and RST®, offer you the possibility of carrying out a complete electrical installation in accordance with binding installation regulations, including all equipment.
                    </p>
                    <p className="mt-5 fs-6 " >
                        You can find more information about Plug & Play in our info portal.
                    </p>
                </div>
                <div className="col-lg-5 col-md-9 col-12">
                    <img src={pi1} alt="image not support" className='img-fluid' />
                </div>
            </div>
        </section>

        <section className="bg-gray py-5 my-5" >
            <div className="container my-5">
                <div className="row justify-content-md-around justify-content-center align-items-center">
                    <div className="col-lg-5 col-md-9 col-12 mb-lg-0 mb-3 ">
                        <img src={pi2} alt="image not suppor" className='img-fluid w-100' />
                    </div>
                    <div className="col-lg-4 col-md-9 col-12">
                        <h1 className="fs-2 text-lg-start text-center" >
                            CONNECTION TECHNOLOGY
                        </h1>
                        <p className="mt-5" >
                            In addition to conventional connection technology, there are many reasons to use pluggable equipment, which includes speedy assembly as well as the simple implementation of changes and extensions. See for yourself!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Product