import {useEffect , useState} from 'react'
import {
    Card,
    Row,
    Col
} from "react-bootstrap";
import Timeconverter from './Timeconverter';

const Temperatuures = ({data : {Payload , timestamp}}) => {
    const [temperatures, setTemperatures] = useState(false)

    function setvalues(temp){
        setTemperatures(temp)
    }
        
    useEffect(() => {
        setvalues(Payload)
    },[Payload])

    const AllChildTopicsData = () => {
        if(temperatures ){
          // console.log('alldata------',allMachinesres)
          var Alldetail = []
        //   for (const [key, value] of Object.entries(temperatures)) {
            var Payloadvalues = []
            var PayloadKeys = []
            var unit = '(°C)'
            // console.log(key , value.Payload)
            for (const [subkey, subvalue] of Object.entries(temperatures)) {
            //   console.log(subkey , subvalue)
                Payloadvalues.push(<li className='py-1 ' key={`${subkey}&&${subvalue}`} >{subvalue} {unit}</li>)
                PayloadKeys.push(<li className='py-1' key={`${subvalue}&Key${subkey}`} >{subkey} :</li>)
            }
           
        //   }
          Alldetail.unshift(
            // <Row className='py-2' key={`keyCard${PayloadKeys}No&${Payloadvalues}`} >
            //     <Col lg="9" md="8" xs="9" className='px-0'>
            //     <ul className="icon-big text-start card-category  ultynone">
            //         {PayloadKeys}
            //     </ul>
            //     </Col>
            //     <Col lg="3" md="4" xs="3" className='px-0'>
            //     <ul className="numbers ultynone card-category py-1"> 
            //         {Payloadvalues}
            //     </ul>
            //     </Col>
            // </Row>
            <div className='d-flex justify-content-between' key={`keyCard${PayloadKeys}No&${Payloadvalues}`} >
            <ul className="icon-big text-start card-category p-0 ultynone">
                  {PayloadKeys}
              </ul>
              {/* </Col>
              <Col lg="3" md="4" xs="3" className='px-0'> */}
              <ul className="numbers ultynone card-category py-1"> 
                  {Payloadvalues}
              </ul>
          </div>
        )
          return(
            Alldetail
          )
        }else{
          return 'loading'
        }
      }



  return (
    <Col className='pe-0' xl='5' lg='7' >
    <Card className="card-stats">
        <Card.Header className='pt-1' >
        <Row className='justify-content-between align-items-center text-end ' >
            <Col xs="3">
            <div className="icon-big text-center icon-danger">
                {/* <i class="bi bi-thermometer-snow text-danger"></i> */}
                {/* <i class="bi bi-thermometer-sun text-danger"></i> */}
                <i className="bi bi-thermometer-half text-danger"></i>
            </div>
            </Col>
            <Col xs="6">
            <div className="numbers"> 
                <Card.Title as="h4">Temperatures</Card.Title>
                {/* <p className="card-category fs-4"><sup className='fs-2 fw-bolder' >.</sup>C</p> */}
                
            </div>
            </Col>
        </Row>
        <hr></hr>

        </Card.Header>
        <Card.Body className='pt-1' >
        <Row className='px-2' >
            <AllChildTopicsData/>
        </Row>
        </Card.Body>
        {timestamp ?
                <Timeconverter DateNtime={timestamp}/>
            : '' }
        
    </Card>
    </Col>
  )
}

export default Temperatuures