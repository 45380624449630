import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'

const Timeconverter = ({DateNtime , ansOnly = false }) => {
    const [lastUpdate , setLastUpdate] = useState()
    const [dataDate , setDataDate] = useState()

    function updateValues(){
      let timeofdata = new Date(DateNtime)
        setLastUpdate(timeofdata.toLocaleTimeString())
        setDataDate(timeofdata.toLocaleDateString())
    }

    useEffect(() => {
        updateValues()
    },[DateNtime])

    

    if(ansOnly){
      return (
         <Card.Title as="h5">
            <div className='fs-6' > <i className="bi bi-clock-history "></i> {dataDate} </div>
            <div className='fs-6' >{lastUpdate}</div>
         </Card.Title> 
      )
    }else{
    return(
      <Card.Footer>
        <hr></hr>
        <div className="stats">
        <i className="bi bi-clock-history"></i> {dataDate} at {lastUpdate}
        </div>
      </Card.Footer>
    )
    }
  
}

export default Timeconverter