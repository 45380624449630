import React , { useState , useEffect , useMemo } from 'react';
import { userMachinesForDashboard , getUserInfo   } from '../config';
import { useSelector , useDispatch } from 'react-redux';
import { getMacs } from '../store/actions/machineAction';
import '../css/animate.min.css';
import '../css/demo.css';  
import '../css/dashboard1.css';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CircleMinutesGraphs ,
  SpeedGraph ,
  MinetesGraph ,
  DashboardNav ,
  Timeconverter ,
  Temperatuures ,
  CurrentRecipe
} from '../Components/DashboardComponents';

function Dashboard({getmachines , MachineNum}) {
// ========================================== Variable =========================================== \\ 

  const [allMachines, setAllMachines] = useState(false)
  const [allData, setAllData] = useState(false)
  // const [allmacno , setAllmcno] = useState()  
  const [currentSelectedMacData , setCurrentSelectedMacData] = useState(false)
  var SideBarTogglerBtnClasses = ' btn col-1 d-md-none d-block ms-0 m-3 p-0  selectmacBtn '
  var SideBarClasses = 'col-lg-2 col-md-3 col-sm-4 col-5 p-3  dashboardsideBar bg-white ' 
  // const [machineDetails , setMachineDetails] = useState(false)
  const dispatch = useDispatch()
  const allMachinesresInRedux = useSelector(state => state.MachineReducer.machines)
  const [userData, setUserData] = useState()

// ========================================== Variable =========================================== \\ 
const allMachinesres = useMemo(() => {
  if (allMachinesresInRedux?.length > 0) {
    return [...allMachinesresInRedux].sort((a, b) => a.id - b.id);
  }else{
    return allMachinesresInRedux
  }
}, [allMachinesresInRedux]);
  

// ========================================== useEffect =========================================== \\ 

// useEffect( () => {
//   console.log('-----------', statisticsData ,'---------')
// }, [statisticsData , setStatisticsData])

useEffect( () => {
  const res = receiveUserData()
  var data = allMqttMachine()
  var result =  dispatch(getMacs())
  // console.log('result------  ------' , userData , '------  -------result')
}, [])




// useEffect(()=>{
//   // console.log('---------- mac ---------',MachineNum , '---------------------------')
//   setAllmcno(MachineNum)
// },[MachineNum])

const allmacno = useMemo(() => {
  return MachineNum 
},[MachineNum])

useEffect(() => {
  CurrentSelectedMacData()
  // cleanData()
},[allmacno])

// useEffect(() => {
//   console.log('allmacno' , allmacno)
// },[allmacno])

// async function DayForADay(){
//   if(allmacno){
//     var result  = await addDataForADay(currentSelectedMacData , allMachinesres[allmacno - 1].id)
//     console.log('re re result' , result)
//   }
// }

// function cleanData(){
//   if(allmacno){
//     var result  =  cleanupOldData(allMachinesres[allmacno - 1].id)
//     // console.log('re re result' , result)
//   }
// }



function CurrentSelectedMacData(){
  if(allmacno){
    setCurrentSelectedMacData(allMachinesres?.filter(item => item.id == allmacno)?.[0]?.data)
  }
  else{
    return
  }
}

function senddatatolayout(){
  var result = getmachines(allMachinesres)
}


useEffect(() => {
  CurrentSelectedMacData()
  senddatatolayout()
  // cleanData()
},[allMachinesres])
// useEffect(() => {
//   console.log('allMachinesres------  ------' , allMachinesres , '------  -------allMachinesres')
// },[allMachinesres])


// ========================================== useEffect =========================================== \\ 

// ========================================== Founctions =========================================== \\ 


const receiveUserData = async () => {
  const result = await getUserInfo()
  // console.log("User Data ", result)
  setUserData(result)
}


const allMqttMachine = async () => {
  var result = await userMachinesForDashboard()
  setAllMachines(result)
}



// ============================================ Founctions ======================================== \\ 




  return (
    <div className='container-fluid Dashboard overflow-x-hidden px-0' >      
        <div className='row Dashboard-row ' >
          <div className='col-12 dashboardDetailBar pb-5 px-1 ' >
                      {allmacno ? 
                          currentSelectedMacData ? 
                          <Container fluid className='my-3' >
                            {currentSelectedMacData.MessageBanner && currentSelectedMacData.MessageBanner.Payload  ?
                            <Row className='px-3 pb-2' >         
                              <Col>
                                <Card className="card-stats my-3 ">
                                    <Card.Body>
                                      <Row className='mb-0' >
                                        <ul className='ultynone d-flex justify-content-center mb-0'>
                                          {/* <li>
                                            status : {currentSelectedMacData.MessageBanner.Payload.status} 
                                          </li> */}
                                          <li className='fs-4' >
                                             {currentSelectedMacData.MessageBanner.Payload.message} 
                                          </li>
                                          {/* <li>
                                            Messege : {currentSelectedMacData.MessageBanner.Payload.trigger_time} 
                                          </li> */}
                                        </ul>
                                      </Row>
                                    </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            :''
                            }
                            <Row className='p-0 m-0' >
                            
                              <Row className='pe-0' >
                                  {currentSelectedMacData.CurrentSpeed ?
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3"  sm="6" xs="12">
                                      <Card className="card-stats">
                                        <Card.Body>
                                          <Row>
                                            <Col xs="5">
                                              <div className="icon-big text-center icon-warning">
                                                <i className="bi bi-speedometer2 text-success"></i>
                                              </div>
                                            </Col>
                                            <Col xs="7">
                                              <div className="numbers">
                                                <p className="card-category">Current Speed</p>
                                                <Card.Title as="h4">{currentSelectedMacData.CurrentSpeed.Payload['Current Speed'][0]}</Card.Title>
                                                <p className="card-category">(PPM)</p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        {currentSelectedMacData.CurrentSpeed && currentSelectedMacData.CurrentSpeed.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.CurrentSpeed.timestamp}/>
                                          : '' }
                                      </Card>
                                  </Col> 
                                  :
                                  ''}

                                  {currentSelectedMacData.Statistics ? 

                                    <Col className='pe-0 mb-xl-0 mb-3' xl="3"  sm="6" xs="12">
                                      <Card className="card-stats">
                                        <Card.Body>
                                          <Row>
                                            <Col xs="5">
                                              <div className="icon-big text-center icon-info">
                                                  <i className="bi bi-speedometer2 text-info"></i>

                                              </div>
                                            </Col>
                                            <Col xs="7">
                                              <div className="numbers">
                                                <p className="card-category">Average Speed</p>
                                                <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Average Speed']}</Card.Title>
                                                <p className="card-category">(PPM)</p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        {/* <Card.Footer>
                                          <hr></hr>
                                          <div className="stats">
                                            <i className="far fa-clock-o mr-1"></i>
                                            In the last hour
                                          </div>
                                        </Card.Footer> */}
                                        {currentSelectedMacData.Statistics && currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                          : '' }

                                      </Card>
                                    </Col>

                                  : '' }

                                  {currentSelectedMacData.Statistics ? 

                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3"  sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="bi bi-bar-chart text-danger"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers">
                                              <p className="card-category">Performance</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Performance (%)']}</Card.Title>
                                              <p className="card-category">(%)</p>
                                              
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* <Card.Footer>
                                        <hr></hr>
                                        <div className="stats">
                                          <i className="far fa-clock-o mr-1"></i>
                                          In the last hour
                                        </div>
                                      </Card.Footer> */}
                                      {currentSelectedMacData.Statistics && currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                          : '' }
                                      </Card>
                                    </Col>

                                    : '' }
                                  
                                  {currentSelectedMacData.Alive || currentSelectedMacData.Alive == 0  ? 
                                    <Col className='pe-0 mb-xl-0 mb-3' xl="3"  sm="6" xs="12">
                                      <Card className="card-stats">
                                        <Card.Body>
                                          <Row>
                                            <Col xs="5">
                                              <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-favourite-28 text-primary"></i>
                                              </div>
                                            </Col>
                                            <Col xs="7">
                                              <div className="numbers">
                                                <p className="card-category">Last Heartbeat</p>
                                                {/* <Card.Title as="h4">{currentSelectedMacData.Alive.
                                                  timestamp}</Card.Title> */}
                                                  
                                        {currentSelectedMacData.Alive && currentSelectedMacData.Alive.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Alive.timestamp} ansOnly={true} />
                                          : '' }
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                          <hr></hr>
                                          <div className="stats">
                                            <i className="fas fa-redo mr-1"></i>
                                            Updated
                                          </div>
                                        </Card.Footer>
                                      </Card>
                                    </Col>
                                  : '' }
                              </Row>
                          
                              {/* Speed & minutes Graph */}
                              <Row className='pe-0'>
                                  {
                                    // currentSelectedMacData.CurrentSpeed ?
                                    //   console.log(currentSelectedMacData.CurrentSpeed)
                                    // :
                                    
                                    currentSelectedMacData.CurrentSpeed ?
                                      <SpeedGraph data={currentSelectedMacData.CurrentSpeed} />
                                    :
                                      ''
                                  }

                                  {
                                  currentSelectedMacData.Statistics ?
                                  
                                  <CircleMinutesGraphs data={currentSelectedMacData.Statistics} currentMac={allmacno} />
                                  :
                                    ''
                                  }
                              </Row>
                              {/* Speed & minutes Graph */}
                          
                              {currentSelectedMacData.Statistics ?
                                <Row className='pe-0'>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-danger">
                                              <i className="bi bi-percent text-success"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers"> 
                                              <p className="card-category">Utilisation</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Utilisation (%)'][0]}</Card.Title>
                                              <p className="card-category">(%)</p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* <Card.Footer>
                                        <hr></hr>
                                        <div className="stats">
                                          <i className="fas fa-redo mr-1"></i>
                                          Update Now
                                        </div>
                                      </Card.Footer> */}
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
 
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-warning">
                                              <i className="bi bi-trash3-fill text-"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers">
                                              <p className="card-category">Scrap Rate</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Scrap Rate (%)'][0]}</Card.Title>
                                              <p className="card-category">(%)</p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* <Card.Footer>
                                        <hr></hr>
                                        <div className="stats">
                                          <i className="far fa-calendar-alt mr-1"></i>
                                          Last day
                                        </div>
                                      </Card.Footer> */}
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-info">
                                              <i className="bi bi-123 text-info"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers">
                                            
                                              <p className="card-category">Pack Counter</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Pack Counter'][0]}</Card.Title>
                                              <p className="card-category">(Packs)</p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                      <Card className="card-stats">
                                        <Card.Body>
                                          <Row>
                                            <Col xs="5">
                                              <div className="icon-big text-center icon-warning">
                                                <i className="bi bi-123 text-warning"></i>
                                              </div>
                                            </Col>
                                            <Col xs="7">
                                              <div className="numbers">
                                                <p className="card-category">Pack Totaliser</p>
                                                <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Pack Totaliser'][0]}</Card.Title>
                                                <p className="card-category">(Packs)</p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        {/* <Card.Footer>
                                          <hr></hr>
                                          <div className="stats">
                                            <i className="far fa-calendar-alt mr-1"></i>
                                            Last day
                                          </div>
                                        </Card.Footer> */}
                                        {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }

                                        
                                      </Card>
                                  </Col>
                                </Row>    
                                :
                                ''
                              }
                              <Row className='pe-0' >
                              {/* Minustes */}
                              {
                                currentSelectedMacData.Statistics ?
                                <MinetesGraph data={currentSelectedMacData.Statistics} />
                                :''
                              }
                              {/* Minustes */}
                              
                              {
                              currentSelectedMacData.CurrentData ?
                                <CurrentRecipe data={currentSelectedMacData.CurrentData
                                } />
                              : ''
                              }

                              </Row>

                              {currentSelectedMacData.Statistics ?
                                <Row className='pe-0 '>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-danger">
                                              <i className="bi bi-calendar2-x text-secondary"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers"> 
                                              <p className="card-category">MTTF</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['MTTF'][0]}</Card.Title>
                                              <p className="card-category">(Mins)</p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* <Card.Footer>
                                        <hr></hr>
                                        <div className="stats">
                                          <i className="fas fa-redo mr-1"></i>
                                          Update Now
                                        </div>
                                      </Card.Footer> */}
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
 
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-warning">
                                              <i className="bi bi-calendar2-check text-success"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers">
                                              <p className="card-category">MTTR</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['MTTR'][0]}</Card.Title>
                                              <p className="card-category">(Mins)</p>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* <Card.Footer>
                                        <hr></hr>
                                        <div className="stats">
                                          <i className="far fa-calendar-alt mr-1"></i>
                                          Last day
                                        </div>
                                      </Card.Footer> */}
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                    <Card className="card-stats">
                                      <Card.Body>
                                        <Row>
                                          <Col xs="5">
                                            <div className="icon-big text-center icon-info">
                                              <i className="bi bi-exclamation-triangle text-danger"></i>
                                            </div>
                                          </Col>
                                          <Col xs="7">
                                            <div className="numbers">
                                            
                                              <p className="card-category">Faults</p>
                                              <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Faults'][0]}</Card.Title>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }
                                    </Card>
                                  </Col>
                                  <Col className='pe-0 mb-xl-0 mb-3' xl="3" sm="6" xs="12">
                                      <Card className="card-stats">
                                        <Card.Body>
                                          <Row>
                                            <Col xs="5">
                                              <div className="icon-big text-center icon-warning">
                                                <i className="bi bi-clock-history text-danger"></i>
                                              </div>
                                            </Col>
                                            <Col xs="7">
                                              <div className="numbers">
                                                <p className="card-category">Faulted Time</p>
                                                <Card.Title as="h4">{currentSelectedMacData.Statistics.Payload['Faulted Time'][0]}</Card.Title>
                                                <p className="card-category">(Mins)</p>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        {/* <Card.Footer>
                                          <hr></hr>
                                          <div className="stats">
                                            <i className="far fa-calendar-alt mr-1"></i>
                                            Last day
                                          </div>
                                        </Card.Footer> */}
                                        {currentSelectedMacData.Statistics.timestamp ?
                                          <Timeconverter DateNtime={currentSelectedMacData.Statistics.timestamp}/>
                                        : '' }

                                        
                                      </Card>
                                  </Col>
                                </Row>    
                                :
                                ''
                              }

                              {
                              currentSelectedMacData.Temperature ?
                                <Row>
                                  <Temperatuures data={currentSelectedMacData.Temperature} />
                                </Row>
                                : ''
                              }
                          </Row>
                                                    
                          
                          
                          </Container>
                          :
                          <div className='container p-md-5 p-3' >
                           
                            <div className='row p-3 border border-3 justify-content-center placeholder-glow' >
                            <h3 className='text-center fs-1 ' > Statistics </h3>

                              <ul className='machineSttisticsCon'>  
                                <li className='machineSttisticsItem fs-4 p-1 placeholder-glow '>
                                  <span className="placeholder col-5 p-2 "></span>
                                </li>
                                <li className='machineSttisticsItem fs-4 p-1 placeholder-glow '>
                                  <span className="placeholder col-6 p-2 "></span>
                                </li>
                                <li className='machineSttisticsItem fs-4 p-1 placeholder-glow '>
                                  <span className="placeholder col-4 p-2 "></span>
                                </li>
                              </ul>
                            </div>
                          </div>
                      :
                        <div className='container text-center macDashboard ' >
                          {userData ?
                            <div className='row py-5 h-100 ' >
                                  <div className='d-flex flex-column container justify-content-center align-items-center ' >
                                    {/* <div className='' > */}
                                    {userData.profilePic ? 
                                          <div className=' mx-5 my-3 h-100 w-100 brStandard dashboardOrganizationImg'
                                          style={{
                                            backgroundImage: `url(${userData.profilePic})` ,
                                          }}
                                          >
                                          </div> 
                                        :
                                          ''
                                    }
                                    {/* </div> */}
                                    <p className='text-center dashboardOrganizationP fs-4  my-3 mb-5' >
                                      Welcome to your dashboard. To view data, select a machine number from the left.
                                    </p> 
                                  </div>
                            </div>
                            :                 
                            <div className='row py-5 h-100 ' >
                                  <div className='d-flex flex-column container justify-content-center align-items-center placeholder-wave ' >
                                  
                                  <div className=' m-3 h-100 w-100 placeholder brStandard dashboardOrganizationImg'>
                                  </div> 
                                  <p className='text-center dashboardOrganizationP fs-4  my-3 mb-5' >
                                    Welcome to your dashboard. To view data, select a machine number from the left.
                                  </p> 
                                  </div>
                            </div>
                          }
                        </div>  
                      }
                      
          </div>
        </div>
    </div>
    
  )
}

export default Dashboard


