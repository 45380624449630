import { userMachinesForDashboard , rdb , auth ,db  } from '../../config/index'
import {
    collection,
    query,
    where,
    onSnapshot
  } from "firebase/firestore";

function getMacs() {
    return async (dispatch) => {
        try{
            rdb.ref('machines').on("child_changed",async (snap) => {
                // let data = []
                // console.log('data in action changed',snap.val()); // will return updated user object
                // var data = await  userMachinesForDashboard(dispatch);
                const changedChildKey = snap.key;

                // Get the full path or directory of the changed child
                const fullPath = snap.ref.toString();

                // console.log('Changed child key:', changedChildKey);
                // console.log('Full path:', fullPath);
                var data = await  userMachinesForDashboard(fullPath);
                dispatch({
                  type: 'GET_MACHINES',
                  payload: data,
                });
            });
    
            const uid = auth.currentUser.uid
            const q = query(collection(db, "users"), where("uid", "==", uid));
            
            onSnapshot(q, async (querySnapshot) => {
                // console.log('data in onsnap',querySnapshot); // will return updated user object
                var data =  await userMachinesForDashboard();
                dispatch({
                  type: 'GET_MACHINES',
                  payload: data,
                });
            });
    

        } catch (error) {
            console.error('Error occurred:', error);
        }
        


        // rdb.ref('machines').on("onValueWritten", snap => {
        //     // let data = []
        //     console.log('data in action',snap.val()); // will return updated user object
        //     var result =  userMachinesForDashboard(dispatch)
        // });
    }
  }

export {
    getMacs
}

