const header = {
    menus: [
        { title: 'Home' , id: '/' , icon:'bi bi-house-door fw-bold' },  
        { title: 'Profile', id: '/Profile'  , icon:"nc-icon nc-circle-09 fw-bold" },
        { title: 'Dashboard', id: '/Dashboard'  , icon:"nc-icon nc-chart-pie-35 fw-bold" },
        // { title: 'About', id: '/About' },
        //{ title: 'Product', id: '/Product' },
        { title: 'Contact', id: '/Contact' , icon:"bi bi-person-rolodex fw-bold"  },
    ]
}

export default header