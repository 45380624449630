import React from 'react'
import sliderimage1 from '../assets/images/slider/slide1.png'
import sliderimage2 from '../assets/images/slider/slide2.png'
import sliderimage3 from '../assets/images/slider/slide3.png'
import graySecImg1 from "../assets/images/graysection/banner1.jpg"
import graySecImg2 from  "../assets/images/graysection/img2.png"
import { Link , useNavigate } from 'react-router-dom'

import Slider from '../Components/Slider'
const Home = () => {
  return (
    <>
        <Slider
            si1={sliderimage1}
            si2={sliderimage2}
            si3={sliderimage3}
        />

        <section className="container py-5 px-1">
			<div className="scene">
				<div className="container container-text d-flex justify-content-center ">
					<div className="text-center col-11" >
						<h1 className='text-center'><strong>WELCOME TO WHERRY INDUSTRIAL ELECTRONICS</strong></h1>
						<p className="my-5">
						We are solution provider of PCB motion controllers, we have equipment working within 70 countries worldwide.
						</p>
						<h2 className='text-center'><strong>INDUSTRY EXPERTISE</strong></h2>
						<p className="my-5">
						For more than 25 years, we have been developing solutions and manufacturing PCB motion controller that are tailored exactly to your application and requirements. You will find a selection of our solutions portfolio in the following sections.
						</p>
					</div>
				</div>
			</div>
		</section>

        <section className="container-fluid bg-gray mt-3 py-3">
			<div className="container">
				<div className="row my-5 justify-content-md-around justify-content-center align-items-center text-lg-start text-center">
					<div className="col-lg-5 col-md-9">
						<img src={graySecImg1} className='d-block img-fluid' alt="your browser does not support this img"/>
					</div>
					<div className="col-lg-5 col-md-7 py-lg-0 py-3">
						<h1 className="fs-3 mb-lg-5 text-lg-center text-center">
						<strong><span className="fw-light" >NEW ONLINE PORTAL </span> COMING SOON</strong>
						</h1>
						<p>
						Track your machines activity with our real-time dashboard. Request a 30 day trial <Link to="/Contact" className='text-primary' >
                  Here
              </Link>
						</p>
						
					</div>
				</div>
				<div className="row my-5 justify-content-md-around justify-content-center align-items-center text-lg-start text-center">
					<div className="col-lg-5 col-md-7 py-md-0 py-3">
						<h1 className="fs-3 mb-3 fw-light text-lg-center text-center">
							<strong>BREAKDOWN SUPPORT</strong>
						</h1>
						<p>
						The quickest way to assess and solve a problem is remotely. 
						Avoid sales and customer service, and have
						peace of mind that you will be directly speaking to one of
						our expert engineers from the offset.
						A dedicated engineer will continue to support you until
						your problems are solved.
						</p>
					</div>
					<div className="col-lg-5 col-md-9">
						<img src={graySecImg2} className='d-block img-fluid' alt="your browser does not support this img"/>
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default Home