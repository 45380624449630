// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import 'firebase/compat/database'
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  updatePassword,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  confirmPasswordReset
} from "firebase/auth";
import {
  getFirestore,
  collection,
  setDoc,
  getDocs,
  query,
  doc,
  updateDoc,
  where,
  orderBy,
  addDoc,
  getDoc
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL , uploadBytes } from "firebase/storage";
import swal from 'sweetalert';
import axios, { all } from 'axios';
import { useState , useEffect} from 'react';
import {
  getDatabase ,
  set ,
  onValue ,
  ref as rtdbref,
  child ,
  push ,
  get ,
  update ,
  endAt ,
  orderByChild ,
  limitToLast
} from 'firebase/database'
// import * as myMqtt from 'mqtt'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyFeSm1CluSv6BmHin4BWT-fu8KjsyRM0",
  authDomain: "wherry-industrial-electronics.firebaseapp.com",
  databaseURL: "https://wherry-industrial-electronics-default-rtdb.firebaseio.com",
  projectId: "wherry-industrial-electronics",
  storageBucket: "wherry-industrial-electronics.appspot.com",
  messagingSenderId: "475375290669",
  appId: "1:475375290669:web:5a01152ee9b59496946ba4"
};


if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);//Auth
const db = getFirestore(app)//DB
const storage = getStorage();
const rdb = firebase.database()
// const database = firebase.database();
// ================================== Get Users ==================================== \\
async function getUsers() {
  const q = query(collection(db, "users") , orderBy("date", "desc"));
  const querrySnapshot = await getDocs(q);
  let data = [];
  querrySnapshot.forEach((doc) => {
    data = [...data, doc.data()];
  });
  return data;
}

// ================================== Get Admin ==================================== \\
async function getAdmin() {
  const q = query(collection(db, "Admin"), where("admin", "==", true));
  const querrySnapshot = await getDocs(q);
  let data = [];
  querrySnapshot.forEach((doc) => {
    data = doc.data();
  });
  // console.log('getAdmin()'.data) 
  return data;
}

// ================================== User Register ==================================== \\
                /// ======== send mail ================== \\\
function sendVerificationMail(firstName , email , emailVerified){
    const RegisteredUserName = firstName
    const RegisteredUserEmail = email
    const RegisteredUserVerify = emailVerified
    axios
    .post('/sendverEmail',{
      RegisteredUserName,
      RegisteredUserEmail,
      RegisteredUserVerify
    }).then(() => console.log('Email delivered successfully'))
    .catch((e) => console.log('Error in send verification Email', e.message))
    return ;
}

function onVerifyEmail(user){
  var result =  user.emailVerified = true
  console.log('on verified ka result ====----===-->>>', result)
  return result
}


const userRegister = async (form) => {
    const { firstName , lastName , organization , email , password } = form;
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const uid = result.user.uid;
    const date = new Date().toLocaleDateString({
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    // var verify = sendEmailVerification(result.user);
    // console.log('verify ===============>>>>' , verify)
    const IsEmailVerified = result.user.emailVerified
    const emailresult = sendVerificationMail(firstName , email , IsEmailVerified);
    console.log('emailresult ====-----====>>>' , emailresult)


    const selected = false
    await setDoc(doc(db, "users", uid), {
      firstName,
      lastName,
      organization,
      email,
      date,
      uid,
      selected,
    });
    // console.log('result ==========>>> ' , result)

    
};



// ================================== User LogIn ==================================== \\
async function SignIn (email,password) {
    await signInWithEmailAndPassword(auth, email, password)
}

// ================================== User Log Out ==================================== \\
const logOut = async (popup = '') => {
    // console.log('logOut', auth.currentUser)
    await signOut(auth)
      .then(() => {
        if(!popup) {
        swal({
            title: "Log Out!",
            text: "Log Out your account",
            icon: "warning",
            button: "OK",
        });
        }
      })
      .catch((error) => {
        alert("Signout Error ", error.message);
      });
};

// ================================== select User ==================================== \\
async function addSelectUser(suid){
  const uid = suid;
  // console.log(uid)
  const userRef = doc(db, "users", uid)
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querrySnapshot = await getDocs(q);
  let data = {}
  querrySnapshot.forEach((doc) => {
    data = doc.data();
  });
  var select = data.selected
  await updateDoc(userRef, {
     selected: true
  });
}

// ================================== Current User Info ==================================== \\

async function getUserInfo() {
  const uid = auth.currentUser.uid
  const q = query(collection(db, "users"), where("uid", "==", uid)) 
  const querySnapshot = await getDocs(q);
  let data = {}
  querySnapshot.forEach((doc) => {
    data = doc.data()
  });
  // console.log('this is our current user',uid)
  if(data.PDF){
  let filteredPDF = []
  let allpdf = await getPdf();
  allpdf.forEach((i , index )=> {
    data.PDF.forEach((usrPdf) => {
      if(i.id === usrPdf){
        filteredPDF.push(i)
        // console.log('filteredpdf=========',filteredPDF)
      }
    })
  })
  data.PDF = filteredPDF
  }
  // console.log('lastData-------------------------',data,'------------------------lastData')
  return data
}

// ================================== Get getPdf ==================================== \\
async function getPdf() {
  const q = query(collection(db, "PDF") , orderBy("date", "desc"));
  const querrySnapshot = await getDocs(q);
  let data = [];
  querrySnapshot.forEach((doc) => {
    var dcdawithOutid = doc.data()
    dcdawithOutid.id = doc.id
    data = [...data, dcdawithOutid]
  });
  // console.log('data === --- ==== ---' , data)
  return data;
}

// ================================== Get currentUserPDFs ==================================== \\
async function currentUserPDFs() {
  const uid = auth.currentUser.uid
  const q = query(collection(db, "users"), where("uid", "==", uid) , orderBy("date", "desc"));
  const querrySnapshot = await getDocs(q);
  let data = [];
  querrySnapshot.forEach((doc) => {
    data = doc.data()
    // console.log('doc === --- ==== ---' , data)
  });
  // console.log('ye hain hamare current user',auth.currentUser)
  // console.log('data === --- ==== ---' , data)
  // return data;
}


function sendContactMail(form){
  const { userName , email , subject , message } = form;
  
  axios
  .post('/sendConatctEmail',{
    userName , email , subject , message 
  }).then(() => console.log('contact Email delivered successfully'))
  .catch((e) => console.log('Error in send contact Email', e.message))
  return ;
}

//  ================================= set Contact ======================================  \\
const contactFormToAdmin = async (form) => {
  const { userName , email , subject , message } = form;
  
  const date = new Date().toLocaleDateString({
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  var result = await addDoc(collection(db, "Contact"), {
    userName,
    email,
    subject,
    message,
    date
  });
  sendContactMail(form)
  // console.log('result ==========>>> ' , result)  
};

//  ================================= get Contact ======================================  \\
async function getContactForms() {
  const q = query(collection(db, "Contact") , orderBy("date", "desc"));
  const querrySnapshot = await getDocs(q);
  let data = [];
  querrySnapshot.forEach((doc) => {
    const ConMail = {...doc.data(), id: doc.id }
    data.push(ConMail)
    console.log('Conmail data',data)
  });
  return data;
}

async function getContactFormById(id){
  const docRef = doc(db, "Contact" , id)
  const docSnap = await getDoc(docRef)
  // console.log(docRef)
  return docSnap.data()
}

// ================================== Admin LogIn ==================================== \\
async function AdminSignIn(email,password) {
  var logO = await logOut(true)
  // console.log(logO)
  var result =  await getAdmin()
  if(result.email === email && result.password === password){
    const adminStatus = await signInWithEmailAndPassword(auth, email, password)
    return true
  }else{
    return false
  }
}


// ================================== getMachineData ==================================== \\
async function getMachineData() {
  try {
      const response = await   axios.post('/sendMachineData');
      const data = response.data
      // console.log('data========>>' , data);
  } catch (error) {
    console.error('error======>>>>', error);
  }
}


// ================================== getRealtimeMachineData ==================================== \\
async function getRealtimeMachineData(){
  let allMachiine = [];
  let medata = await rdb.ref('machines').once('value', snapshot => {
    snapshot.forEach(childSnapshot => {
      var keyName = childSnapshot.key;
      var data = childSnapshot.val()
      allMachiine.push({id : keyName ,data })  
    })
    // console.log('allMachiine=============== in fire >>>>',allMachiine)
  })
  return allMachiine
}


// ================================== addSelectUserPDFs ==================================== \\
async function addSelectUserPDFs(useruid , pdfs){
  const uid = useruid;
  const Spdfs = pdfs;
  // console.log( '--------------------------' ,uid)
  // console.log( '-------------------------- firestrore ' ,Spdfs)
  const userRef = doc(db, "users", uid)
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querrySnapshot = await getDocs(q);
  let data = {}
  querrySnapshot.forEach((doc) => {
    data = doc.data();
  });
  // console.log('dalta',data)
  if(data.PDF){
  data.PDF.map(i => {
    Spdfs.push(i)
  })
  }
  await updateDoc(userRef, {
     PDF:  Spdfs
  });

  var result = await addSelectUser(uid)

}
  
// ================================== addSelectUserMachines ==================================== \\
async function addSelectUserMachines(useruid , mac){
  const uid = useruid;
  const Smachines = mac;
  // console.log( '--------------------------' ,uid)
  // console.log( '-------------------------- firestrore Smachines' ,Smachines)
  
  const userRef = doc(db, "users", uid)
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querrySnapshot = await getDocs(q);
  let data = {}
  querrySnapshot.forEach((doc) => {
    data = doc.data();
  });
  // console.log('dalta',data)
  if(data.Machines){
    // console.log('fire mai else mai hogya kaam')
    data.Machines.map(i => {
      Smachines.push(i)
    })
    }
    // console.log('smachines',Smachines)
  await updateDoc(userRef, {
     Machines: Smachines
  });
  var result = addSelectUser(uid)

}


// async function userMachinesForDashboard(d){
async function userMachinesForDashboard(){
  const uid = auth.currentUser.uid
  // const result = await getUserInfo()

  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  const result =  docSnap.data()

  // console.log('result of user info' , result)

  const rtmac = await getRealtimeMachineData()
  // console.log('result of macs info' , rtmac)

  let data = []
  if(result.selected && result.Machines){
    result.Machines.forEach(i => {
      rtmac.forEach(maci => {
        if(i === maci.id){
          // console.log('i = '+ i +' id = '+maci.id)
          data.push(maci)
        }
      })
      // dispatch({
      //   type: 'GET_MACHINES',
      //   payload: data  
      // })
    })
    
  }
  // else{
  //   d({
  //     type: 'GET_MACHINES',
  //     payload: data
  //   })
  // }
  
  

  return data;
}

async function uploadImage(file) {
  // console.log('file', file)
  const imageRef = ref(storage, 'profileImages/' + file.name)
  const uploadedImage = await uploadBytes(imageRef, file)
  const url = await getDownloadURL(uploadedImage.ref)
  return url
}

async function updateProfile(data) {
  // console.log('currentUser --->', auth.currentUser.uid)
  const uid = auth.currentUser.uid
  await setDoc(doc(db, "users", uid), data, { merge: true });
}




async function getDataForSpeedGraph(machineNoAndTopic){
    // console.log('machineNoAndTopic in config',machineNoAndTopic)
  let lastUpdatedValueTime = null;
  const FinalData = [];
  let lastUpdatedValue = null;
    
  const currentTime = Date.now();
  const cutoffTime = 4 * 60 * 1000; // 4 min in milliseconds
  let allMachiine = [];
  let lastDayMacDataRef = rdb.ref(`lastdayMacData/${machineNoAndTopic}`)
  // const snapshot = await lastDayMacDataRef.orderByChild('timestamp').limitToLast(8).once('value');
  const snapshot = await lastDayMacDataRef.orderByChild('timestamp').once('value');
    // console.log('lastDayMacDataRef in config',lastDayMacDataRef)

  const getedData = [];
  const updates = {};
  var max = null
  var selectedTimeArray = []
  snapshot.forEach((dataSnapshot) => {
    const key = dataSnapshot.key;
    var doc = dataSnapshot.val()
    var timeofdata = doc.timestamp
    var CurrentSpeed = doc['Current Speed'][0]

    // Find the maximum value
    if (max === null || CurrentSpeed > max) {
      max = CurrentSpeed;
    }


    
  //  ================================================================================= \\ 
    if(CurrentSpeed !== undefined ){

      var dataForPush = {}
      if (lastUpdatedValueTime === null || timeofdata >= lastUpdatedValueTime + 10 * 60 * 1000) {
        lastUpdatedValueTime = timeofdata
        // console.log('lastUpdatedValueTime in config ----------------->>>>',lastUpdatedValueTime)
        dataForPush = {
          // CurrentSpeed : CurrentSpeed + 3,
          CurrentSpeed,
          timeofdata : new Date(timeofdata).toLocaleTimeString(),
          dateofData : new Date(timeofdata).toLocaleDateString(),
          lastUpdatedValueTime : new Date(timeofdata).toLocaleTimeString(), 
          heigh : max
        }
      }else{
        dataForPush = {
          // CurrentSpeed : CurrentSpeed + 3,
          CurrentSpeed,
          timeofdata : ' ',
          dateofData : new Date(timeofdata).toLocaleDateString(),
          lastUpdatedValueTime : new Date(timeofdata).toLocaleTimeString(),
          heigh : max
        }
      }


      FinalData.push(dataForPush)
      // }
    }
  //  ================================================================================= \\ 

    // console.log('=================',FinalData,'================')

  });
  
  return FinalData
}


async function resetPassword(form){
  const auth = getAuth();
  const currentPassword = form.prePassword
  const newPassword = form.password

  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, currentPassword);

  try {
    // Re-authenticate the user
    var reauthResult =  await reauthenticateWithCredential(user, credential);
    console.log('reauthResult' , reauthResult)
    // If re-authentication is successful, update the password
    await updatePassword(user , newPassword);
    console.log("Password updated successfully.");
    swal({
      title: "Done!",
      text: "your password reset seccessfully",
      icon: "success",
      button: "OK",
    });
  } catch (error) {
    console.error("Error in re-authentication or password update:", error);
    swal({
      title: "Error!",
      text: error.message,
      icon: "error",
      button: "OK",
    });
  }
};

async function confirmResetPassword(oobCode, newPassword) {
  try {
    await confirmPasswordReset(getAuth(), oobCode, newPassword);
    return true; // Password reset was successful
  } catch (error) {
    console.error("Error resetting password:", error);
    return false; // Password reset failed
  }
}





export {
    userRegister,
    SignIn,
    logOut,
    auth,
    onAuthStateChanged,
    AdminSignIn,
    getUsers,
    addSelectUser,
    getAdmin,
    getUserInfo,
    db,
    rdb,
    ref,
    storage,
    getDownloadURL,
    uploadBytes,
    getPdf,
    contactFormToAdmin,
    getContactForms,
    getContactFormById,
    getMachineData,
    getRealtimeMachineData,
    addSelectUserPDFs,
    currentUserPDFs ,
    addSelectUserMachines,
    userMachinesForDashboard,
    updateProfile,
    uploadImage,
    getDataForSpeedGraph,
    resetPassword,
    confirmResetPassword
    // addDataForADay,
    // cleanupOldData
}