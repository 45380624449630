import {useState , useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { getContactFormById } from '../config';

function ContactDetail() {
    const [CDetail , setCDetail] = useState();
    const  params = useParams();
    const { contactId } = params;
    

    
    useEffect(() => {
        getFormDetail()
    },[])

    const getFormDetail = async () => {
        const result = await getContactFormById(contactId)
        setCDetail(result)
        // console.log('r1', result)
        // console.log('r2', CDetail)
    }

    const {userName , subject , message ,  email , date } = CDetail || {}

    return (
        <div className='ContactDetail container my-5 py-3' >
            <div className='row' >
                <h1 className='text-center' >ContactDetail</h1>
            </div>
            <div className='row p-5 justify-content-between w-100' >
                <div className='p-2 my-1 ContactItems' > Name : {userName} </div>
                <div className='p-2 my-1 ContactItems' > Email : <a href={`mailto:${email}`} className='fs-5' > {email} </a> </div>
                <div className='p-2 my-1 ContactItems' > SubJect : {subject} </div>
                <div className='p-2 my-1 ContactItems' > Date : {date} </div>
                <div className='my-3 ContactItems'>
                    <div className='fs-3 fw-bold' >Message :-</div>
                    <p className='p-3' >
                        {message}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ContactDetail