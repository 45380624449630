import { useState } from 'react'
import { Link } from 'react-router-dom'

const LogInForm = ({Formheading , submitFunction , formFor}) => {
    
  const [ form, setForm ] = useState()
  const updateForm = (e, key) => {
    setForm( {...form, [key] : e.target.value})
  }

  return (
    <section className="loginSection d-flex justify-content-center align-items-center container ">
        <form className="my-3 border p-md-5 p-3 " >
            <div className="form-heading">
                <h1 className="text-center">{ Formheading }</h1>
              </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1 aria-autocomplete='true'" className="form-label fs-5 ">Email address</label>
              <input type="email" autoComplete='username' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={e => updateForm(e,  'email')}/>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" aria-autocomplete='true'  className="form-label fs-5">Password</label>
              <input type="password" autoComplete='current-password' className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'password')}/>
            </div>
            <div className="d-flex py-1  align-items-center" >
                {!formFor ?
                <div className="" >
                  Don't have a user account ? 
                   <Link to="/SignUp" className='my-green' > Register Here</Link>
                </div> : ''
                }
                
            </div>
            <div className="d-flex justify-content-between justify-content-sm-end  align-items-center my-3" >
                <div className="border-0 me-sm-2 me-0 py-2 text-center" >
                  <Link to="/ForgotPassword" className='my-green' >Forgotten Password </Link>
                </div>
                <div className="border-0 col-md-3 col-sm-4 col-5 py-2 text-center submit-btn" onClick={() => submitFunction(form) } >Log In</div>
            </div>
        </form>
    </section>
  )
}

export default LogInForm