import { useState } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import { contactFormToAdmin } from '../config'
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col
} from "react-bootstrap";

const Contact = () => {
    const [ form, setForm ] = useState()
    const updateForm = (e, key) => {
      setForm( {...form, [key] : e.target.value})
    }

                /// ======== send mail ================== \\\
const sendConatctEmail = async (form) => {
    if(form){
      try {
        const result = await contactFormToAdmin(form)
          await swal({
                title: "Congratulation!",
                text: "Email gone Successfully!",
                icon: "success",
                button: "OK",
          });
        }catch (e) {
          console.log(e.message)
            swal({
                title: "Error!",
                text: 'invalid data',
                icon: "error",
                button: "OK",
              });
        }
    }else{
      swal({
        title: "Error!",
        text: 'invalid data',
        icon: "error",
        button: "OK",
      });
    }
}




  return (
    <section className="loginSection d-flex justify-content-center align-items-center container py-md-3">
        <form className="my-3 border p-3" >
            <Card.Header className='mb-md-5 mb-3' >
                <Card.Title as="h3" className='fw-normal text-center fs-2' >Contact Us</Card.Title>
            </Card.Header>
            <div className='row mb-2' >
              <div className=" col-md-6">
                <label>Name</label>
                <input type="text" className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'userName')}/>
              </div>
              <div className="col-md-6">
                <label>Email</label>
                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={e => updateForm(e,  'email')}/>
              </div>
            </div>
            
            <div className="mb-2">
              <label>Subject</label>
              <input type="text" className="form-control" id="exampleInputPassword1" onChange={e =>  updateForm(e, 'subject')}/>
            </div>
            <div className='my-5N' >
                <label >Message</label>
                <textarea className="form-control"  placeholder='Enter Your Message' id="exampleFormControlTextarea1" rows="2" onChange={e =>  updateForm(e, 'message')} ></textarea>
            </div>
            <div className="row py-3 justify-content-end  align-items-center px-3" >
                <div className='border-0 col-md-2 brStandard col-sm-3 col-4 my-sm-0 my-2 py-1 text-center submit-btn' onClick={() => sendConatctEmail(form)} >Submit</div>
            </div>
        </form>
    </section>
  )
}

export default Contact