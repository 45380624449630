import React from 'react'
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import ChartistGraph from 'react-chartist';
import Timeconverter from './Timeconverter';


const MinetesGraph = ({data : {Payload , timestamp}}) => {
  return (
    <Col md="6" className='pe-0 mb-xl-0 mb-3'>
                                <Card>
                                  {/* <Card.Header>
                                    <Card.Title as="h4">2017 Sales</Card.Title>
                                    <p className="card-category">All products including Taxes</p>
                                  </Card.Header> */}
                                  <Card.Body className='pb-0 ' >
                                    <div className="ct-chart mb-0" id="chartActivity">
                                      <ChartistGraph
                                        data={{
                                          // labels: [
                                          //   "10000",
                                          //   "8000",
                                          //   "6000",
                                          //   "4000",
                                          //   "2000",
                                          //   "0"
                                          // ],
                                          series: [
                                            [
                                              Payload.Uptime[0]
                                            ],
                                            [
                                              Payload['Run Minutes'][0]
                                            ],
                                          ],
                                        }}
                                        type="Bar"
                                        options={{
                                          seriesBarDistance: 200,
                                          axisX: {
                                            showGrid: false,
                                          },
                                          height: "245px",
                                        }}
                                        responsiveOptions={[
                                          [
                                            "screen and (max-width: 640px)",
                                            {
                                              seriesBarDistance: 5,
                                              axisX: {
                                                labelInterpolationFnc: function (value) {
                                                  return value[0];
                                                },
                                              },
                                            },
                                          ],
                                        ]}
                                      />
                                    </div>
                                  </Card.Body>
                                  <div className='container py-1'>
                                    <div className="d-flex justify-content-around ">
                                      <div>
                                      <i className="fas fa-circle text-info"></i> Uptime Minutes : {Payload.Uptime[0]} </div>
                                      <div> <i className="fas fa-circle text-danger"></i> Run Minutes : { Payload['Run Minutes'][0] } </div>
                                    </div>
                                    {/* <hr></hr>
                                    <div className="stats">
                                      <i className="fas fa-check"></i>
                                      Data information certified
                                    </div> */}
                                  </div>
                                  {timestamp ?
                                      <Timeconverter DateNtime={timestamp}/>
                                  : '' }
                                </Card>
                              </Col>
  )
}

export default MinetesGraph