import React from 'react'
import { useLocation } from 'react-router-dom';

const Footer = () => {
	const location = useLocation();
    const date = new Date()
	const year = date.getFullYear()
	// Check if the current route is '/dashboard'
	const isDashboardRoute = location.pathname === '/Dashboard';

	// Conditional rendering of the header
if (isDashboardRoute) {
  return null; // Don't render header for the dashboard route
}

  return (
    <footer className="bg-white p-2  " >
		<div className="row justify-content-center" >

			<div className="row justify-content-center" >
			<div className="icon-big text-center icon-warning">                
			<i className="bi bi-envelope-at text-secondary"> info@wherryelectronics.co.uk</i>
            </div>
			<div className="col-md-6 text-center">                
				© {year} Wherry Industrial Electronics. All rights reserved.
			</div>

		</div>
		</div>


	</footer>
  )
}

export default Footer
