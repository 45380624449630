function reducer(state = { machines: [] }, action) {
    // action === {
    //     type: 'ADD_USER',
    //     payload: user
    // }
    // console.log('userinside machine reducer ', state)
    switch(action.type) {
        case 'GET_MACHINES': return { ...state, machines: action.payload }
        default: return state
    }
}

export default reducer