
import image404 from '../assets/images/404.svg' 
import { Link } from 'react-router-dom'

const PageNotFound = () => {

  return (
    <div className='container d-flex justify-content-center align-items-center container py-md-5' >
        <div className='profile-container p-3 bg-white text-center pgNotfnd' >
            <img src={image404} alt='page not fond' className='img-fluid notFound'   />
            <h3  >This page is not found</h3>
            <Link to="/">
              <button  className='px-4 py-2 rounded-pill' >  Back to Home </button>
            </Link>
        </div>
    </div>
  )
}

export default PageNotFound

