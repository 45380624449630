import { useState , useEffect } from 'react'
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import ChartistGraph from 'react-chartist';
import Timeconverter from './Timeconverter';

const CircleMinutesGraphs = ({data :{ Payload , timestamp}}) => {
    const minutesObjValues = ['Run Minutes', 'Stop Minutes' , 'E Stop Minutes']
    const [minutesInPercent , setMinutesInPercent ] = useState(false) 
    const [minutesInPercentage , setMinutesInPercentage ] = useState(false) 
var rrr = [
    '1',
        '1',
        '1'
]
    // console.log( 'CircleMinutesGraphs' , data)
    function AllMinsDataPercentage(){
        var totalvalue = 0
        var minutesObjValuesinPercent = []
        var minutesObjValueses = []
        minutesObjValues.forEach(element => {
            // console.log( 'CircleMinutesGraphs' , Payload[element])
            if(Payload[element]){
                totalvalue += Number(Payload[element])
            }
        })
        minutesObjValues.forEach(element => {
            // console.log(Payload[element][0])
            if(Payload[element][0] && Payload[element][0] !== 0 ){
                    minutesObjValuesinPercent.push(`${(Number(Payload[element][0]) / totalvalue * 100 ).toFixed(2)}%`)
                    minutesObjValueses.push((Number(Payload[element][0]) / totalvalue * 100 ).toFixed(2))
            
            }else{
                // console.log('yes')
                minutesObjValuesinPercent.push(`${(Number(Payload[element][0]) / totalvalue * 100 )}%`)
                minutesObjValueses.push((Number(Payload[element][0]) / totalvalue * 100 ))
            }
            
        

        })
            // console.log("minutesObjValueses" , minutesObjValueses)

        setMinutesInPercentage(minutesObjValuesinPercent)
        if(minutesObjValueses.length < 1){
            return 
        }else{
            return minutesObjValueses
        }
    }

    useEffect(() => {
        var result = AllMinsDataPercentage()
        setMinutesInPercent(result)
    } , [Payload])


  return (
    
        minutesInPercent ?
    <Col md="4" className='pe-0 mb-xl-0 mb-3' >
        <Card >
            <Card.Header>
              <Card.Title as="h4" className='mb-1 align-items-center '>Statistics</Card.Title>

                <p className="card-category ">
                    <i className="fas fa-circle text-info mx-1"></i> 
                    Run Minutes : {Payload['Run Minutes'][0]}
                </p>
                <p className="card-category ">
                    <i className="fas fa-circle text-danger mx-1"></i>
                    Stop Minutes : {Payload['Stop Minutes'][0]}
                </p>
                <p className="card-category ">
                    <i className="fas fa-circle text-warning mx-1"></i>
                    E Stop Minutes : {Payload['E Stop Minutes'][0]}
                </p>


            </Card.Header>
            <Card.Body >
                <div
                    className="ct-chart ct-perfect-fourth my-0"
                    id="chartPreferences"
                >
                    
                    <ChartistGraph
                        data={{
                            // labels: [
                            //     `${minutesInPercent[0]}%` || '',
                            //     `${minutesInPercent[1]}%` || '',
                            //     `${minutesInPercent[2]}%` || ''
                            // ],
                            labels: minutesInPercentage,
                            // series: [
                            //     minutesInPercent[0] || '',
                            //     minutesInPercent[1] || '',
                            //     minutesInPercent[2] || ''
                            // ],
                            series: minutesInPercent,
                        }}
                        type="Pie"
                    />
                    
                </div>
                {/* <hr></hr> */}
                    {/* <div className="stats">
                        <i className="far fa-clock"></i>
                        Campaign sent 2 days ago
                    </div> */}
            </Card.Body>
            {timestamp ?
                <Timeconverter DateNtime={timestamp}/>
            : '' }
        </Card>                      
    </Col>
    : ''
            
  )
}

export default CircleMinutesGraphs