import {useState , useEffect} from 'react'
import logo from '../../assets/images/logo2.png'
import { Link } from 'react-router-dom'
import { logOut  } from '../../config'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './style.css'
import { DashboardNav } from '../DashboardComponents';
{/* <div key={item.id} className="col-xl-1 opacity-75 col-lg-2 col-md-2 px-xl-3 px-0 nav-link fs-5 fw-bold mx-4">
                            <Link to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className="nav-link"> {item.title} </Link>
                        </div> */}

function MenusSection({menus , userState , setForCloseNav , forCloseNav , machineExist , currentpath }){
    // console.log('menus -----' , menus)
    return(
        <>
        {menus.map((item , index) => {
            if(item.title === 'Dashboard' ){
                if(userState && machineExist){
                    // console.log('true ------' , machineExist)
                    return(
                        // <Navilink key={item.id}  id={item.id} title={item.title} />
                        <Link key={item.id} to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active mx-2" : 'nav-link mx-2'} > 
                            <span className='d-md-block d-sm-none d-block text' > {item.title} </span>   
                            <span className={`d-md-none d-block ${item.icon} fs-5`}  />  
                        </Link>

                    )
                }else{
                    return
                }
            }
            if(item.title === 'Profile' ){
                if(userState){
                    return(
                        <Link key={item.id} to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active mx-2" : 'nav-link mx-2'}> 
                            <span className='d-md-block d-sm-none d-block text' > {item.title} </span>   
                            <span className={`d-md-none d-block ${item.icon} fs-5`}  />  
                        </Link>
                    )
                }else{
                    return
                }
            }
            return(
                <Link key={item.id} to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active mx-2" : 'nav-link mx-2'}> 
                   <span className='d-md-block d-sm-none d-block text' > {item.title} </span>   
                   <span className={`d-md-none d-block ${item.icon} fs-5`}  />  
                </Link>
            )
        })}
        
        </>
    )
    
}




const DashboardHeader = ({data: { menus} , userState  , sideBarState , setSlideBarState , currentPage , allmacno , allMachinesres , userData }) => {
    const [navdown , setNavdown] = useState(false)
    const [forCloseNav , setForCloseNav] = useState(false)
    const [machineExist , setMachineExist] = useState(false) 
    const [dashboardItems , setDashboardItems] = useState('') 
    
    var navDowncClasses = 'nav-body row w-100 d-md-flex justify-content-end align-items-center text-center'
    const navigate = useNavigate()
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
      if(dimensions.width >= 768){
        setNavdown(false)
        // console.log(navdown)
    }
}
    
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
    
})
useEffect(() => {
    if(userState && userState.Machines){
        if(userState.Machines.length === 0){
            setMachineExist(false)
        }else{
            // console.log('userStateMachine ----- >>>' , userState )
            setMachineExist(userState.Machines)
        }
    }else{
        setMachineExist(false)
    }
},[userState])
// close nav bar \\

useEffect(() => {
    setNavdown(false)
},[forCloseNav , setForCloseNav])

const logedOut = async () => {
    var result =await logOut()
    navigate(`/LogIn`)
}
const location = useLocation();
var headno = false
var padY = 'py-2'
var colmn = 'col-lg-2 col-md-3'
var fonSize = 'fs-5'
// Check if the current route is '/dashboard'
var currentpath = location.pathname
const isDashboardRoute = currentpath === '/Dashboard';
// Conditional rendering of the header
if (isDashboardRoute) {
    headno = true // Don't render header for the dashboard route
    padY = 'py-1'
    colmn = 'col-xxl-1 py-xxl-2 col-lg-2 col-md-2'
    fonSize = 'fs-6'
    // console.log('ye lo ' , headno)

}






  return (
    <div className="DadhboardHeader px-lg-0 py-1  " >
        <div className="container-lg d-flex justify-content-between align-items-center "> 
        {/* {userData && <DashboardNav userData={userData} allMachinesres={allMachinesres} allmacno={allmacno} />} */}
                <div className='col-md-3 col-sm-4 d-flex align-items-center justify-content-start px-0' >
                    <i className='nc-icon nc-chart-pie-35 fw-bold  mx-2' />
                    <div >{currentPage}</div>
                </div>

                {allmacno ?
                    <div className='fs-5 p-0 fw-normal  align-self-center dashboardMacHeadingUnderline' >
                           { allmacno.allmacno  } 
                    </div>
                    :
                    <div className='col-1 fs-5 pe-2 fw-normal  align-self-center ' >
                           
                    </div>
                }

                        <button onClick={() => {
                            setDashboardItems('DashboardItemsOpen')
                        }} className='col-1 d-sm-none d-block openBtn px-2 pt-1 border-0' >
                            <i className="bi bi-list fs-2 "></i>
                        </button>

                {/* ============== Menus Section ============== */}
                    <div className={`DashboardItems ${dashboardItems} nav-body d-flex align-items-center justify-content-center  py-2`} >
                        <button onClick={() => {
                            setDashboardItems('')
                        }} className='d-sm-none d-block closeBtn' >
                            <i className='bi bi-x-lg' ></i>
                        </button>
                        <MenusSection menus={menus} userState={userState} forCloseNav={forCloseNav} setForCloseNav={setForCloseNav} machineExist={machineExist} currentpath={currentpath} />
                        {
                        userState ?  
                        <div onClick={() => {
                            logedOut    () 
                            setForCloseNav(!forCloseNav)
                            } } className='login-btn nav-link mx-md-2 rounded-pill' >
                            Log Out
                        </div>
                        :
                        <Link to="/LogIn" onClick={() => setForCloseNav(!forCloseNav)} className="login-btn nav-link mx-md-2 rounded-pill" >
                            Log In 
                        </Link>
                        }                
                    </div>
                {/* ============== Menus Section ============== */}
            
                


        </div>
    </div>
    )
}

export default DashboardHeader