import {useState , useEffect} from 'react'
import logo from '../../assets/images/logo2.png'
import { Link } from 'react-router-dom'
import { logOut  } from '../../config'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



function MenusSection({menus , userState , setForCloseNav , forCloseNav , machineExist , currentpath , logedOut , navdown , navDowncClasses }){
    return(
    <div className={navdown ?  ' navdown nav-body ' + navDowncClasses   : navDowncClasses + ' d-none' } >
        {menus.map((item , index) => {
            if(item.title === 'Dashboard' ){
                if(userState && machineExist){
                    // console.log('true ------' , machineExist)
                    return(
                        <div key={item.id} className="nav-item  px-md-1 py-md-0 p-3">
                            <Link to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active" : 'nav-link'}> {item.title} </Link>
                        </div>
                    )
                }else{
                    return
                }
            }
            if(item.title === 'Profile' ){
                if(userState){
                    return(
                        <div key={item.id} className="nav-item px-md-1 py-md-0 p-3">
                            <Link to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active" : 'nav-link'}> {item.title} </Link>
                        </div>
                    )
                }else{
                    return
                }
            }



            return(
            <div key={item.id} className="nav-item px-md-1 py-md-0 p-3">
                <Link to={item.id} onClick={() => setForCloseNav(!forCloseNav)} className={currentpath === item.id ? "nav-link nav-link-active" : 'nav-link'}> {item.title} </Link>
            </div>
            )
        })}
        {
        userState ?  
        <div onClick={() => {
            logedOut() 
            setForCloseNav(!forCloseNav)
            } } className={`login-btn nav-link rounded-pill mx-1`} >
            Log Out
        </div>
        :
        <Link to="/LogIn" onClick={() => setForCloseNav(!forCloseNav)} className="login-btn nav-link rounded-pill mx-1" >
            Log In 
        </Link>
        }
    </div>
    )
    
}




const  Header= ({data: { menus} , userState }) => {
    const [navdown , setNavdown] = useState(false)
    const [forCloseNav , setForCloseNav] = useState(false)
    const [machineExist , setMachineExist] = useState(false) 
    var navDowncClasses = 'nav-body w-100 d-md-flex justify-content-end align-items-center  '
    const navigate = useNavigate()
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
        if(dimensions.width >= 768){
            setNavdown(false)
            // console.log(navdown)
        }
    }
        
        window.addEventListener('resize', handleResize)
        return _ => {
        window.removeEventListener('resize', handleResize)
        }
        
    })
    useEffect(() => {
        if(userState && userState.Machines){
            if(userState.Machines.length === 0){
                setMachineExist(false)
            }else{
                // console.log('userStateMachine ----- >>>' , userState )
                setMachineExist(userState.Machines)
            }
        }else{
            setMachineExist(false)
        }
    },[userState])
    // close nav bar \\
    useEffect(() => {
            setNavdown(false)
    },[forCloseNav , setForCloseNav])

    const logedOut = async () => {
        var result =await logOut()
        navigate(`/LogIn`)
    }
    const location = useLocation();
    var headno = false
    var padY = 'py-2'
    var colmn = 'col-lg-2 col-md-3'
    var fonSize = 'fs-5'
    // Check if the current route is '/dashboard'
    var currentpath = location.pathname
    const isDashboardRoute = currentpath === '/Dashboard';
    // Conditional rendering of the header
    if (isDashboardRoute) {
        headno = true // Don't render header for the dashboard route
        padY = 'py-1'
        colmn = 'col-xxl-1 py-xxl-2 col-lg-2 col-md-2'
        fonSize = 'fs-6'
        // console.log('ye lo ' , headno)

    }





  return (
    <header className=" py-1  " >
        <nav className=" container-lg d-flex justify-content-md-center justify-content-between">

            {/* ============== logoSection ============== */}
                 <div className="logo-conatiner" >       
                    <Link to="/" onClick={() => setForCloseNav(!forCloseNav)} >
                        <img src={logo} alt="Wherry Indusrial Electronics" className="logoonHead img-fluid"/>
                    </Link>
                </div>
            {/* ============== logoSection ============== */}

            {/* ============== Toggle Btn at Mobile Devices ============== */}
                <button onClick={() => setNavdown(!navdown)} className="border-0 d-md-none d-block bg-none" id="toggleBtn">
                    <i className="bi bi-list fs-1"></i>
                </button>
            {/* ============== Toggle Btn at Mobile Devices ============== */}
            
            {/* ============== Menus Section ============== */}
                <MenusSection menus={menus} userState={userState} forCloseNav={forCloseNav} setForCloseNav={setForCloseNav} machineExist={machineExist} currentpath={currentpath} logedOut={logedOut} navdown={navdown} navDowncClasses={navDowncClasses} />
            {/* ============== Menus Section ============== */}
        </nav>
    </header>
    )
}

export default Header