import { useEffect , useState }  from "react";
import Sidebar from "../Components/Sidebar";
import DashboardHeader from "../Components/DashboardHeader";
import Header from "../Components/Header";
import header from "../utils/header";
import { useLocation } from "react-router-dom";
import { Routes , Route } from 'react-router-dom';
import { auth , onAuthStateChanged , getUserInfo , getAdmin , getMachineData } from '../config';
import { Dashboard , Home , Contact , ContactDetail , ContactEmails , AdminPanel , AdminPanelLogIn , Profile , LogIn , SignUp , PageNotFound , About , Product } from "../Pages";
import Recipeviewer from "../Components/Recipeviewer";
import { DashboardNav } from "../Components/DashboardComponents";
function Layout({user , protectedAdminRoute}) {
  const [allMachinesres , setAllMachinesres] = useState()
  const [allMacno , setAllMacno] = useState()
  const [sideBarState , setSlideBarState] = useState()
  const [currentPage , setCurrentPage] = useState('Dashboard')
  const [userData, setUserData] = useState()

  useEffect( () => {
    const res = receiveUserData()
  }, [user])
  

  // =================== DashBoard  ==================== \\
    function getAllMachinesresFromDashboard(data) {
        setAllMachinesres(data)
        // console.log('data in layout ---===--==---',data)
    }
    function getAllMacnoFronSidebar(data) {
      setAllMacno(data)
      if(data.allmacnoDetailPage == 'Recipeviewer'){
        setCurrentPage('Recipe Viewer')
      }else{
        setCurrentPage('Dashboard')
      }
  
  
        // console.log(' all macno data in layout ---===--==---',data)
    }


    
  const location = useLocation();
  var headno = false
  // Check if the current route is '/dashboard'
  const isDashboardRoute = location.pathname === '/Dashboard';
  

  // Conditional rendering of the header
  if (isDashboardRoute) {
    headno = true // Don't render header for the dashboard route
  }

  const protectedRoute = (item,e) => {
    const result = user ? item : e
    return result
  }

  

  const receiveUserData = async () => {
    const result = await getUserInfo()
    // console.log("User Data ", result)
    setUserData(result)
  }


  return (
    <>
      <div className="wrapper dashboardLayout  ">
        <Sidebar allMachinesres={allMachinesres} getmacno={getAllMacnoFronSidebar} sideBarState={sideBarState} setSlideBarState={setSlideBarState} headno={headno} />
        <div className="main-panel " >
          <DashboardHeader data={header} currentPage={currentPage} userState={user} sideBarState={sideBarState} setSlideBarState={setSlideBarState} userData={userData} allMachinesres={allMachinesres} allmacno={allMacno} />
          {/* {userData && <DashboardNav userData={userData} allMachinesres={allMachinesres} allmacno={allMacno} />} */}

          {
            allMacno ?
              allMacno.allmacnoDetailPage == 'Recipeviewer' ?  
                <Recipeviewer getmachines={getAllMachinesresFromDashboard} MachineNum={allMacno.allmacno} /> 
                : <Dashboard getmachines={getAllMachinesresFromDashboard} MachineNum={allMacno.allmacno} />
          :
          <Dashboard getmachines={getAllMachinesresFromDashboard} MachineNum={allMacno} /> 
          }

          {/* <Dashboard getmachines={getAllMachinesresFromDashboard} MachineNum={allMacno.allmacno} /> */}
        </div>
      </div>
    </>
  );
}

export default Layout;

{/* <Dashboard getmachines={getAllMachinesresFromDashboard} MachineNum={allMacno} /> */}
