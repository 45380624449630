import { useState , useEffect } from 'react'
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import ChartistGraph from 'react-chartist';
import { getDataForSpeedGraph } from '../../config';

const SpeedGraph = ({data : { Topic , Payload} , currentMac}) => {
    const [datafromrdb , setDatafromrdb ] = useState()
    const [currentSpeed , setCurrentSpeed] = useState()
    const [Loading , setLoading] = useState(false)
    const [dataTime , setDataTime] = useState()
    const [dataDate , setDataDate] = useState()
    const [lastUpdate , setlastUpdate] = useState()
    const [max , setMax] = useState()
    async function getGraphData(data){
        var result = await getDataForSpeedGraph(data)
        // console.log('result==========-=======-========',result,'==========-=======-========result')
        setDatafromrdb(result)
    }

    useEffect( () => {
      var result =   getGraphData(Topic)
      // console.log('Payload',Payload,'Payload')
    },[Payload , currentMac])

function ArrangeData(){
  setLoading(true)
  if(datafromrdb){
    var copyCurrentSpeed = []
    var timeofdata = []
    var dateofData = ''
    var lastUpdateData = ''
    datafromrdb.forEach((elements) => {
      // if(copyCurrentSpeed.length >= 10){
      //   return
      // }else{
        copyCurrentSpeed.push(elements.CurrentSpeed)
        if(elements.timeofdata && elements.timeofdata !== ' '){
          timeofdata.push(elements.timeofdata)
        }
        dateofData = elements.dateofData
        lastUpdateData = elements.lastUpdatedValueTime
        setMax(elements.heigh + elements.heigh / 2 )

      // }
    }) 
    setCurrentSpeed(copyCurrentSpeed)

    setDataDate(dateofData)
    // console.log('timeofdata==========-=======-========',timeofdata,'==========-=======-========timeofdata')

    setDataTime(timeofdata)
    setlastUpdate(lastUpdateData)

    setLoading(false)
  }
}

    useEffect(()=>{
      // console.log('datafromrdb==========-=======-========',datafromrdb,'==========-=======-========datafromrdb')
      ArrangeData()
    },[datafromrdb])
    // useEffect(()=>{
    // //   console.log('currentSpeed==========-=======-========',currentSpeed,'==========-=======-========currentSpeed')
      
    //   console.log('dataTime==========-=======-========',dataTime,'==========-=======-========dataTime')
    // },[currentSpeed])
    // useEffect(()=>{
    //   console.log('max==========-=======-========',max,'==========-=======-========max')
    // },[max])
  return (
    <Col className='pe-0 h-100 mb-xl-0 mb-3' md="8">
        <Card>
            <Card.Header className='d-flex justify-content-between opacity-50' >
                <Card.Title as="h4">Speed</Card.Title>
                {
                  Loading &&
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                }
               
            </Card.Header>
            <Card.Body>

                                    <div className="ct-chart " id="chartHours">
                                      { 
                                      currentSpeed ? 
                                      <div className='ChartistGraphWrapper ' >

                                        <ChartistGraph
                                          data={
                                            {                      
                                              // labels: [
                                              //   "9:00AM",
                                              //   "12:00AM",
                                              //   "3:00PM",
                                              //   "6:00PM",
                                              //   "9:00PM",
                                              //   "12:00PM",
                                              //   "3:00AM",
                                              //   "5:00AM",
                                              // ],
                                              // series: [
                                              //   [55 ,12 , 30 , 40 ,60,0 ,255,109]
                                              // ],
                                              // labels: dataTime ,
                                              series: [currentSpeed],
                                            }
                                            }
                                            type="Line"
                                            options={{
                                              low: 0,
                                              high : max,
                                              showArea: false,
                                              height: "280px",
                                              axisX: {
                                                showGrid: false,
                                              },
                                              lineSmooth: false,
                                              showLine: true,
                                              showPoint: false,
                                              fullWidth: false,
                                              chartPadding: {
                                                right: 20,
                                              },
                                            }}
                                            responsiveOptions={[
                                              [
                                                "screen and (max-width: 640px)",
                                                {
                                                  axisX: {
                                                    labelInterpolationFnc: function (value) {
                                                      return value[0];
                                                    },
                                                  },
                                                },
                                              ],
                                            ]}
                                        />
                                        {
                                          dataTime &&
                                          <div className='customLabels py-1' >
                                            {/* <span>9:00AM</span>
                                            <span>9:00AM</span>
                                            <span>9:00AM</span>
                                            <span>9:00AM</span> */}
                                            {
                                              dataTime.map((item,index) => {
                                                return(
                                                  <span>{item}</span>
                                                )
                                              })
                                            }
                                          </div>
                                        }
                                        
                                      </div>
                                      : 
                                      ''}
                                    </div>
                                    {/* <div className="ct-chart d-md-none d-block" id="chartHours">
                                      { 
                                      currentSpeed ? 
                                        <ChartistGraph
                                          data={
                                            {                      
                                              // labels: [
                                              //   "9:00AM",
                                              //   "12:00AM",
                                              //   "3:00PM",
                                              //   "6:00PM",
                                              //   "9:00PM",
                                              //   "12:00PM",
                                              //   "3:00AM",
                                              //   "5:00AM",
                                              // ],
                                              // series: [
                                              //   [55 ,12 , 30 , 40 ,60,0 ,255,109]
                                              // ],
                                              // labels: dataTime ,
                                              series: [currentSpeed],
                                            }
                                            }
                                            type="Line"
                                            options={{
                                              low: 0,
                                              high : max,
                                              showArea: false,
                                              height: "280px",
                                              axisX: {
                                                showGrid: false,
                                              },
                                              lineSmooth: false,
                                              showLine: true,
                                              showPoint: false,
                                              fullWidth: false,
                                              chartPadding: {
                                                right: 20,
                                              },
                                            }}
                                            responsiveOptions={[
                                              [
                                                "screen and (max-width: 640px)",
                                                {
                                                  axisX: {
                                                    labelInterpolationFnc: function (value) {
                                                      return value[0];
                                                    },
                                                  },
                                                },
                                              ],
                                            ]}
                                        />
                                      : 
                                      ''}
                                    </div> */}
            </Card.Body>
            <Card.Footer>
              <div className="legend">
                <i className="fas fa-circle text-info"></i>
                Current Speed
              </div>
              {
                dataDate && lastUpdate ? 
                <>
                  <hr></hr>
                  <div className="stats">
                    <i className="fas fa-history"></i> Updated {dataDate} at {lastUpdate}
                  </div>
                </>
                  : ''
              }
              
            </Card.Footer>
        </Card>
    </Col>
  )
}

export default SpeedGraph