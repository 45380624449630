import React , { useState } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import { AdminSignIn } from '../config'
import swal from 'sweetalert'
import LogInForm from '../Components/LogInForm'

const AdminPanelLogIn = () => {
  const navigate = useNavigate()
  const signIn = async (form) => {
    const { email, password } = form
    // console.log(email , password)
    try {
      const result = await AdminSignIn(email , password)
      if (result == true){
        swal({
          title: 'Log In',
          text: 'Logged in successfully',
          icon: 'success',
          // button: 'Ok'
      })
      // window.location.reload()
      navigate('/AdminPanel')
      }else{
        swal({
          title: 'Error',
          text: 'invalid email or password',
          icon: 'error',
          button: 'Ok'
      })
      }
    }
    catch(e){
        swal({
            title: 'Error',
            text: e.message,
            icon: 'error',
            button: 'Ok'
        })
    }
  }


  return (
    // <button onClick={signIn} >singnnn</button>
    <LogInForm
    Formheading='Admin Panel'
    submitFunction={signIn}
    formFor
    />
    
  )
}

export default AdminPanelLogIn