import React , { useState , useEffect , useMemo } from 'react'
import { userMachinesForDashboard , getUserInfo   } from '../../config'
import { useSelector , useDispatch } from 'react-redux'
import { getMacs } from '../../store/actions/machineAction'
import mcimg from '../../assets/images/machines/machines.svg'
import mcimg2 from '../../assets/images/machines/machines2.svg'
import Slider from '../Slider'
import ChartistGraph from 'react-chartist';
import Chartist from 'chartist';
import '../../css/animate.min.css';
import '../../css/demo.css';  
import '../../css/dashboard1.css';
// import '../css/light-bootstrap-dashboard-react.css.map';
// import '../css/light-bootstrap-dashboard-react.min.css';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { CircleMinutesGraphs , SpeedGraph , MinetesGraph } from '../DashboardComponents'
import './style.css';
import { motion , AnimatePresence } from "framer-motion"
import wordsToNumbers from 'words-to-numbers';



function Recipeviewer({getmachines , MachineNum}) {
// ========================================== Variable =========================================== \\ 

  const [allMachines, setAllMachines] = useState(false)
  const [Recipes, setRecipes] = useState(false)
  const [filter , setFilter] = useState(true)
  const [allData, setAllData] = useState(false)
  const [allmacno , setAllmcno] = useState()  
  const [currentSelectedMacData , setCurrentSelectedMacData] = useState(false)
  var SideBarTogglerBtnClasses = ' btn col-1 d-md-none d-block ms-0 m-3 p-0  selectmacBtn '
  var SideBarClasses = 'col-lg-2 col-md-3 col-sm-4 col-5 p-3  dashboardsideBar bg-white ' 
  // const [machineDetails , setMachineDetails] = useState(false)
  const dispatch = useDispatch()
  const allMachinesresInRedux = useSelector(state => state.MachineReducer.machines)
  const [userData, setUserData] = useState()

// ========================================== Variable =========================================== \\ 
const allMachinesres = useMemo(() => {
  if (allMachinesresInRedux?.length > 0) {
    return [...allMachinesresInRedux].sort((a, b) => a.id - b.id);
  }else{
    return allMachinesresInRedux
  }
}, [allMachinesresInRedux]);
// ========================================== useEffect =========================================== \\ 

// useEffect( () => {
//   console.log('-----------', statisticsData ,'---------')
// }, [statisticsData , setStatisticsData])

useEffect( () => {
  const res = receiveUserData()
  var data = allMqttMachine()
  var result =  dispatch(getMacs())
  // console.log('result------  ------' , userData , '------  -------result')
  
  // console
}, [])



function CurrentSelectedMacData(){
  setSelectedId(false)
  setSelectedPayloaddetail(false)
  setSelectedRecipieFunctions(false)
  setSelectedRecipeManualAdj(false)
    if(allmacno){
      setCurrentSelectedMacData(allMachinesres?.filter(item => item.id == allmacno)?.[0]?.data)
    }
    else{
      return
    }
  }
  
useEffect(() => {
  // CurrentSelectedMacData()
  senddatatolayout()
  // cleanData()
},[allMachinesres])


useEffect(()=>{
  // console.log('---------- mac ---------',MachineNum , '---------------------------')
  setAllmcno(MachineNum)
},[MachineNum])
useEffect(() => {
  CurrentSelectedMacData()
},[allmacno])


function senddatatolayout(){
  var result = getmachines(allMachinesres)
}

function recipeDataFilter(data){
    var filteredObject = {};
    for (var key in data) {
        if (key.toLowerCase().includes("recipe")) {
            filteredObject[key] = data[key];
        }
    }   
    setRecipes(filteredObject)
    // console.log(filteredObject)
}


useEffect(() => {
  // console.log('currentSelectedMacData------  ------' , currentSelectedMacData , '------  -------currentSelectedMacData')
  recipeDataFilter(currentSelectedMacData)
},[currentSelectedMacData])


// ========================================== useEffect =========================================== \\ 

// ========================================== Founctions =========================================== \\ 


const receiveUserData = async () => {
  const result = await getUserInfo()
  // console.log("User Data ", result)
  setUserData(result)
}


const allMqttMachine = async () => {
  var result = await userMachinesForDashboard()
  setAllMachines(result)
}



// ============================================ Founctions ======================================== \\ 
const [selectedId, setSelectedId] = useState(null)
const [selectedPayloaddetail, setSelectedPayloaddetail] = useState(null)
const [selectedRecipeFunctions, setSelectedRecipieFunctions] = useState(null)
const [selectedRecipeManualAdj, setSelectedRecipeManualAdj] = useState(null)

const AllChildTopicsData = () => {
    if(Recipes ){
      // console.log('alldata------',allMachinesres)
      function setCrntRecipe(paylo){
        var Payloaddetail = []
        var PayloadFunc = []
        var PayloadManual = []
        // console.log(key , value.Payload)
        for (const [subkey, subvalue] of Object.entries(paylo)) {
          var sval = subkey.toLowerCase()
          if(sval === 'registration' || sval == 'registation'  || sval === 'printer' || sval === 'labeller' || sval === 'auto' || sval === 'remote' || sval === 'npnb' || sval === 'ngnc' || sval === 'constant gap' || sval === 'cut to length' ){
            // console.log('subkey', subkey ,"subvalue" , typeof(subvalue[0]))
            PayloadFunc.push(<li key={subkey} className='machineSttisticsItem  '>{subkey} : 
            {
              subvalue[0] ?

               <i className="bi bi-check2 text-success fs-4"></i>
              :
               <i className="bi bi-x-lg text-danger fs-6"></i>
            }

            </li>)
          }else if(sval === 'recipe name' || sval === 'run speed' || sval === 'pack length' || sval === 'registration position' || sval === 'product position' || sval === 'infeed gearratio' || sval === 'slowdown adjust' || sval === 'ngnc start inspection' || sval === 'ngnc end inspection' ){
            if(sval === 'recipe name'){
              Payloaddetail.unshift(<li key={subkey} className='machineSttisticsItem '>{subkey} : 
              {subvalue} </li>)
              // console.log(subkey , subvalue)
            }else{
              Payloaddetail.push(<li key={subkey} className='machineSttisticsItem '>{subkey} : 
              {subvalue} </li>)
            }
          }else{
            PayloadManual.push(<li key={subkey} className='machineSttisticsItem  '>{subkey} : {subvalue} </li>)
          }


          // Payloaddetail.push(<li key={subkey} className='machineSttisticsItem fs-4 '>{subkey} : {subvalue}</li>)
        }
        setSelectedPayloaddetail(Payloaddetail)
        setSelectedRecipieFunctions(PayloadFunc)
        setSelectedRecipeManualAdj(PayloadManual)
      }

      var Alldetail = []
      for (const [key, value] of Object.entries(Recipes)) {
        // var numericalPart = key.toLowerCase().replace('recipe', '');
        // var convertedNumber = wordsToNumbers(numericalPart, { fuzzy: true });
        
        // var RecipeName = key.toLowerCase().split('recipe')[0];
        // console.log('key', numericalPart)
        // console.log('key', convertedNumber)
        // if (key.toLowerCase().includes("recipe")) {
        //   filteredObject[key] = data[key];
        // }
        // console.log('RecipeName' , RecipeName)
        // if(isNaN(convertedNumber)){
            if(filter){
              if(value.Payload['Recipe Name'][0] && value.Payload['Recipe Name'][0] !== '') {
              Alldetail.push(
                <div className='col-xxl-3 col-xl-4 col-sm-6 my-3' key={key} onClick={() => {
                  setSelectedId(key)
                  setCrntRecipe(value.Payload)
                  }}>
                  <Card className="recipeCards card-stats p-3   ">
                    <Card.Body className='px-sm-auto px-0 p-0 card-category ' >  
                        <div className='fs-5 mb-3' >{value.Payload['Recipe Name']} </div>
                        {/* <div>Recipe Name : {value.Payload['Recipe Name']}</div> */}
                        <div>Run Speed : {value.Payload['Run Speed']}</div>
                        <div>Pack Length : {value.Payload['Pack Length']}</div>
                        <div className='mt-3 showMbtn' >Show Detail  </div>
                    </Card.Body>
                  </Card>
                </div>
              )
                }
            }
            else{
              Alldetail.push(
                <div className='col-xxl-3 col-xl-4 col-sm-6 my-3' key={key} onClick={() => {
                  setSelectedId(key)
                  setCrntRecipe(value.Payload)
                  }}>
                   <Card className="recipeCards card-stats p-3   ">
                     <Card.Body className='px-sm-auto px-0 p-0 card-category ' >  
                         <div className='fs-5 mb-3' >{value.Payload['Recipe Name']} </div>
                         <div>Recipe Name : {value.Payload['Recipe Name']}</div>
                         <div>Run Speed : {value.Payload['Run Speed']}</div>
                         <div>Pack Length : {value.Payload['Pack Length']}</div>
                         <div className='mt-3 showMbtn' >Show Detail  </div>
                     </Card.Body>
                   </Card>
                </div>
              )
            }

        // }else{
        // Alldetail[convertedNumber] = (
        //   <div className='col-xxl-3 col-xl-4 col-sm-6 my-3' key={key} onClick={() => {
        //     setSelectedId(key)
        //     setCrntRecipe(value.Payload)
        //     }}>
        //      <Card className="recipeCards card-stats p-3   ">
        //        <Card.Body className='px-sm-auto px-0 p-0 card-category' >  
        //            <div className='fs-4 mb-3' >{key} </div>
        //            <div>Recipe Name : {value.Payload['Recipe Name']}</div>
        //            <div>Run Speed : {value.Payload['Run Speed']}</div>
        //            <div>Pack Length : {value.Payload['Pack Length']}</div>
        //            <div className='mt-3 showMbtn' >Show Detail  </div>
        //        </Card.Body>
        //      </Card>
        //   </div>
        // )}


      }
      
      return(
        Alldetail
      )
    }else{
      return 'loading'
    }
}


// useEffect(()=>{
//   console.log('selectedPayloaddetail',selectedPayloaddetail)
//   console.log('selectedRecipeFunctions',selectedRecipeFunctions)
//   console.log('selectedRecipeManualAdj',selectedRecipeManualAdj)
// },[selectedPayloaddetail,selectedRecipeFunctions,selectedRecipeManualAdj])





  return (
    <div className='container-fluid Dashboard overflow-x-hidden' >      
        <div className='row Dashboard-row align-items-start ' >
          <div className='dashboardDetailBar align-items-start pb-5 py-3' >
                      {currentSelectedMacData ?
                          <AnimatePresence>
                            <Container fluid className='my-3' >
                                <Row  >
                                
                                {
                                  selectedId && selectedRecipeFunctions && selectedPayloaddetail && selectedRecipeManualAdj ?
                                  <div>
                                    
                                      <Card className="card-stats pt-1 pb-2 ">
                                      <button
                                        className=' closeBtnOfDetail px-2' 
                                        onClick={() => {
                                          setSelectedId(null)
                                          setSelectedPayloaddetail(null)
                                          setSelectedRecipieFunctions(null)
                                          setSelectedRecipeManualAdj(null)
                                        }} >
                                          <i className="bi bi-x-lg fs-3"></i>
                                        </button>
                                        <Card.Body className='px-sm-auto px-0 card-category' >  
                                            <h3 className='text-center' >{selectedId} </h3>
                                            <Row>
                                              <Col md='6' >
                                                
                                                <ul className='ultynone mb-5'>
                                                <div className='fs-4 mb-3 fst-underline' >Functions</div>

                                                  {
                                                    selectedRecipeFunctions
                                                  }
                                                </ul>
                                                <ul className='ultynone my-3'>
                                                  {
                                                    selectedPayloaddetail
                                                  }
                                                </ul>
                                              </Col>
                                              <Col md='6' >
                                              <ul className='ultynone mb-5'>
                                                <div className='fs-4 mb-3 fst-underline' >Manual Adjustment</div>

                                                  {
                                                    selectedRecipeManualAdj
                                                  }
                                                </ul>
                                              </Col>
                                            </Row>
                                            {/* <ul className='ultynone'>
                                              { selectedPayloaddetail } 
                                            </ul> */}

                                        </Card.Body>
                                      </Card>
                                  </div>
                                  :
                                  <div >
                                    <div className='d-flex justify-content-end' >
                                      <button 
                                      onClick={() => setFilter(!filter)}
                                      className=' px-3 py-1 border-0 recipeCards card-stats bg-white  card-category ' >
                                         filter <i className="bi bi-filter-left"></i> 
                                      </button>
                                    </div>
                                    <div className='row' >
                                      <AllChildTopicsData/>
                                    </div>
                                  </div>
                                  
                                }
                              </Row>
                            </Container>
                          </AnimatePresence>
                      :   
                        <div className='container text-center macDashboard ' >
                          {userData ?
                            <div className='row py-5 h-100 ' >
                                  <div className='d-flex flex-column container justify-content-center align-items-center ' >
                                    {/* <div className='' > */}
                                    {userData.profilePic ? 
                                          <div className=' mx-5 my-3 h-100 w-100 brStandard dashboardOrganizationImg'
                                          style={{
                                            backgroundImage: `url(${userData.profilePic})` ,
                                          }}
                                          >
                                          </div> 
                                        :
                                          ''
                                    }
                                    {/* </div> */}
                                    <p className='text-center dashboardOrganizationP fs-4  my-3 mb-5' >
                                      Welcome to your dashboard. To view data, select a number from the left.
                                    </p> 
                                  </div>
                            </div>
                            :                 
                            <div className='row py-5 h-100 ' >
                                  <div className='d-flex flex-column container justify-content-center align-items-center placeholder-wave ' >
                                  
                                  <div className=' m-3 h-100 w-100 placeholder brStandard dashboardOrganizationImg'>
                                  </div> 
                                  <p className='text-center dashboardOrganizationP fs-4  my-3 mb-5' >
                                    Welcome to your dashboard. To view data, select a number from the left.
                                  </p> 
                                  </div>
                            </div>
                          }
                        </div>  
                      }
                      
          </div>
        </div>
    </div>
    
  )
}

export default Recipeviewer



